
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
    name: "EduCarousel",
})
export default class EduCarousel extends Vue {
    // props
    @Prop(Boolean) arrows?: boolean;
    @Prop(Boolean) dots?: boolean;

    // data
    firstItemIndex = 0;
    itemsCount = 0;
    containerOffset = 0;

    // computed
    get arrowLeftClass(): string {
        return "arrow left" + ((this.firstItemIndex === 0)? " disabled":"");
    }

    get arrowRightClass(): string {
        return "arrow right" + ((this.firstItemIndex >= this.itemsCount - 1)? " disabled":"");
    }

    get itemsContainerStyle(): any {
        return {
            left: this.containerOffset + "px"
        };
    }

    // methods
    goLeft() {
        if(this.firstItemIndex > 0) {
            const item:HTMLElement|null = this.$el.querySelector(".frame .items > *:nth-child(" + this.firstItemIndex + ")");
            if(item) {
                this.containerOffset = -item.offsetLeft;
                this.firstItemIndex--;
            }
        }
    }

    goRight() {
        if(this.firstItemIndex < this.itemsCount - 1) {
            const item:HTMLElement|null = this.$el.querySelector(".frame .items > *:nth-child(" + (this.firstItemIndex + 2) + ")");
            if(item) {
                this.containerOffset = -item.offsetLeft;
                this.firstItemIndex++;
            }
        }
    }

    // watch

    // hooks
    mounted() {
        const items = this.$el.querySelectorAll(".frame .items > *");
        this.itemsCount = items.length;
    }
}
