import EduOnlyTitle from "@/components/EduOnlyTitle.vue";
import EduRichContent from "@/components/EduRichContent.vue";

export default {
    _path: "/falc-scolaire",
        _layout: "main",
        _pageLayout: "horizontalLayers",
        layers: [
        {
            _component: EduOnlyTitle,
            _name: "support",
            title: "L’accessibilité universelle et l’offre de FALC sur la plateforme Edumalin"
        },
        {
            _component: EduRichContent,
            _name: "content",
            sections: [
                {
                    level: 1,
                    title: "Introduction :",
                    block: [
                        "Dès sa création en 2016, Edumalin a pour vocation de <b>soutenir la scolarité de tous les élèves</b> pour lutter contre les inégalités scolaires. ",
                        "A ce titre, la plateforme ne cesse d'évoluer dans le but de rendre de plus en <b>plus accessibles</b>, à des profils d’élèves variés, les contenus élaborés par les enseignants à partir des programmes officiels de l’Education Nationale du CE2 jusqu’en Terminale. ",
                    ],
                    icon: "/img/illus-demo.svg",
                    width: "20rem"
                },
                {
                    block: [
                        "Depuis 2021, Edumalin a créé un poste de chargé d’inclusion occupé par une enseignante titulaire du CAPPEI<quote><span>Le Certificat d'Aptitude Professionnelle aux Pratiques de l'Education Inclusive (CAPPEI) a été institué par l’Education Nationale pour attester la qualification des enseignants du premier et du second degré qui exercent sur des postes spécialisés au contact des publics handicapés ou en grande difficulté.</span></quote> pour penser l’adaptation au niveau de la plateforme, des fonctionnalités, des contenus et des démarches d’apprentissage, dans le sens de <b>l’accessibilité universelle</b><quote><span>Barile, M., Nguyen, M. N., Havel, A., & Fichten, C. S. (2012). L'accessibilité universelle en pédagogie : des avantages pour toutes et pour tous!. Pédagogie collégiale vol. 25, n° 4, été 2012.</span></quote>. Dans cette optique, l’objectif est que le parcours ne désavantage aucun groupe d’élèves : on recherche une utilisation simple et intuitive, adaptable et flexible, qui permet à tous les élèves de travailler sur les mêmes parcours, en collaboration, si besoin. La manière de présenter le savoir doit rester exigeante mais l’erreur est considérée comme constructive et pas définitive.",
                        "En effet, le but de faire progresser l’inclusion à l’école est de faire travailler tous les élèves ensemble, au sein de leur classe, sous l’impulsion de leurs professeurs qui orchestrent la didactique, la pédagogie et la différenciation. ",
                    ],
                    icon: "/img/features/creation.svg",
                    width: "30rem"
                },
                {
                    block: [
                        "La réflexion se porte sur les fonctionnalités de la plateforme mais aussi sur une offre plus spécialisée, la progressive traduction des cours en <b>FALC : le Facile à Lire et à Comprendre</b>.",
                        "Si vous vous reportez aux sites  officiels, comme Inclusion Europe ou l’UNAPEI ou « <a href='https://www.monparcourshandicap.gouv.fr/glossaire/falc' target='_blank' rel='noreferrer' class='open-in-new-tab'>mon parcours handicap</a> », vous trouverez les principes de base du FALC. C’est <b>une méthode qui a pour but de traduire un langage classique en un langage compréhensible par tous</b> : en priorité et à l’origine les personnes touchées par une déficience intellectuelle, mais aussi, les dys-, les personnes handicapées, les personnes qui maîtrisent mal le Français."
                    ],
                    icon: "/img/inclusion/aiko-falc.png",
                    width: "22rem"
                },
                {
                    block: [
                        "Plus qu’une simple traduction, le FALC est <b>un modèle social</b>.",
                        "<ul><li>Dans l’article « <a href='https://doi.org/10.3917/ls.171.0171' target='_blank' rel='noreferrer' class='open-in-new-tab'>Vous avez dit FALC ?</a> » <quote><span>Canut, E., Delahaie, J. & Husianycia, M. (2020). Vous avez dit FALC ? Pour une adaptation linguistique des textes destinés aux migrants nouvellement arrivés. Langage et société, 171, 171-201. </span></quote>, les auteurs soulignent le lien entre intégration des migrants et accès aux informations administratives mais aussi culturelles.</li>" + 
                        "<li>Aujourd’hui, les musées, les médiathèques, les éditeurs s’inquiètent de l’accessibilité de leur offre, en mettant à disposition du public des fonds en FALC.</li>" + 
                        "<li>Les sites internet, notamment administratifs, mais privés comme publics, doivent aussi suivre cette norme européenne.</li></ul>"
                    ]
                },
                {
                    block: [
                        "En effet, cette disposition va dans le sens des prescriptions européennes et françaises au sujet du nécessaire <b>accès de tous à l’information et à l’autodétermination</b> : d'ailleurs, lors des dernières élections présidentielles, les programmes de tous les candidats ont été transcrits en FALC pour que chacun, quelque soient ses besoins particuliers, puisse contribuer à la société. "
                    ],
                    icon: "/img/inclusion/S3A.svg",
                    width: "19rem"
                },
                {
                    block: [
                        "Dans cette logique, les parcours Edumalin en FALC reprennent intégralement <b>le parcours ordinaire correspondant</b> (Mémoriser, Tâches guidées et QCM) : " +
                        "<ul><li>libre à l’enseignant de les diviser ou d’en supprimer certaines parties pour certains EBEP.</li>" +
                        "<li>Ces parcours en FALC proposent l’ajout d’une <b>Tâche guidée nommée « Inclumalin »</b> : cet exercice adapté s’adresse notamment à des élèves dont le niveau scolaire ou de compréhension diffère de 1 à 2 cycles par rapport aux camarades de leur classe ayant le même âge qu’eux.</li></ul>"
                    ]
                },
                {
                    block: [
                        "Cette TG Inclumalin en FALC peut être utilisée <b>en préparation</b> des temps d’inclusion dans la classe de l’élève (par exemple lors de regroupements en ULIS ou en SEGPA).</br>Elle peut aussi être donnée <b>en parallèle</b> ou <b>en remplacement</b> de la TG ordinaire pour des élèves qui ont besoin d’un allègement de la tâche. Cela évite de multiplier les tiers-temps qui accroissent considérablement le temps dévolu au temps de travail personnel de certains EBEP."
                    ],
                    icon: "/img/inclusion/tg-icon.svg",
                    width: "30rem",
                    height: "6rem"
                },
                {
                    level: 1,
                    title: "1. Les origines du projet inclusion et FALC d’Edumalin",
                },
                {
                    level: 2,
                    title: "Constat sur les obstacles à l’inclusion et à l’accessibilité universelle :",
                },
                {
                    level: 3,
                    title: "Les élèves à Besoins Educatifs Particuliers (EBEP) de <a href='https://www.monparcourshandicap.gouv.fr/actualite/journee-de-leducation-2023-quelles-avancees-pour-les-eleves-handicapes' target='_blank' rel='noreferrer' class='open-in-new-tab'>plus en plus nombreux dans les classes.</a>",
                    block: [
                        "Des malentendus, en présence des inférences, naissent parfois d’une mauvaise compréhension des cours et la double tâche épuise et perd souvent de nombreux élèves à BEP : ce sont les deux obstacles majeurs que les professeurs essaient d’éviter au maximum dans leur classe. "
                    ],
                },
                {
                    block: [
                        "Lorsqu’un élève porteur de dys- épuise toutes ses forces à lire un texte ou une consigne alors que son trouble l’empêche d’acquérir les automatismes de la lecture, sa mémoire ne peut pas fonctionner correctement, que ce soit sa mémoire de travail ou sa mémoire à long terme.", 
                        "L’élève perd alors de vue les objectifs de son travail. Les conséquences sur l’estime de soi sont catastrophiques du fait de la persistance des troubles ou des difficultés."
                    ],
                    icon: "/img/inclusion/dys-icon.svg",
                    width: "15rem"
                },
                {
                    block: [
                        "Que dire alors de l’élève qui souffre de limitations significatives de ses capacités intellectuelles, comme certains élèves d’ULIS, qui appartiennent, par exemple, à une classe de 4ème de par leur âge mais dont le niveau scolaire et le niveau de compréhension sont assimilables à un niveau de cycles 2 ou 3 ?", 
                        "La réception des cours par des élèves allophones dépend souvent de la possibilité qu’ils ont eue de pouvoir être scolarisés antérieurement dans leur pays d’origine. Dans tous les cas de multilinguismes, il ne s’agit pas d’un simple problème de vocabulaire ou de syntaxe. Le multiculturalisme crée parfois des incompréhensions sur le contenu des cours mais aussi sur ce qui est attendu à l’école, par exemple en matière de rédaction, comme la quantité à rédiger ou la nature de ce qui est demandé et de ce qui est valorisé dans un barème d’examen.",
                        "Il est nécessaire avec tous les élèves à BEP <b>d’expliciter, d’étayer, de guider jusqu’à l’imitation</b> parfois et de créer les conditions qui permettent la nécessaire transmission de ce qu'exige, comme compétences souvent tacites, le métier d’élève ou de candidat à un examen. "
                    ],
                },
                {
                    level: 3,
                    title: "Les professeurs et les AESH, acteurs de l’inclusion.",
                    block: [
                        "Face à ces réalités du terrain et à l'accroissement du nombre d’élèves à BEP par classe, des initiatives inclusives scolaires voient le jour ou se renouvellent : expérimentations, dispositifs ULIS / UEE/ UEMA, sections SEGPA, accompagnement et PIAL, aménagements, compensations,...",
                        "Pour les EBEP, est privilégiée la scolarisation en milieu ordinaire dès que cela est possible.",
                        "Par exemple, on parle de la désinstitutionnalisation ou de la transformation des structures du Médico-social à qui il est demandé de changer de fonctionnement pour créer des passerelles de plus en plus nombreuses et fluides entre le milieu ordinaire et le milieu dit protégé (IME, UEE, ITEP, DITEP, DAME, unités mobiles…). Viennent à l’école les personnels des ESMS comme les éducateurs ou les enseignants ou coordinateurs de scolarité. ",
                        "Pour diverses raisons, les professeurs des établissements dits ordinaires constatent une plus grande hétérogénéité des classes et plusieurs regrettent le manque de formation des encadrants, des directions, des enseignants, voire même des AESH. Un grand effort est demandé à ces professionnels, pour rendre accessibles les cours, dans plusieurs disciplines, comme en primaire ou en accompagnement des ESH, ou, pour prendre en charge les BEP de nombreux élèves parmi les 120/150 répartis en classes de 25 à 35 élèves d’un professeur du secondaire."
                    ],
                },
                {
                    block: [
                        "La plateforme Edumalin propose, en plus des activités utilisables telles quelles, des <b>gabarits de cours adaptés</b> à remplir par les professeurs avec leurs propres cours selon un modèle de scénarisation qui met en valeur les points-clefs de leur progression.",
                        "Tous les membres des équipes éducatives peuvent aussi bénéficier du suivi des usages par l'équipe Edumalin et demander <b>des actions de formation</b> sur 3 niveaux dédiés à l’inclusion et à l’accessibilité universelle."
                    ],
                    picture: "/img/app-screen/screen-1.png",
                    customClass: "flexColumn",
                    alternativeText: "bouton créer un travail sur l'application Edumalin",
                    maxWidth: "100%"
                },
                {
                    level: 2,
                    title: "Nos propositions pour répondre à ces difficultés : un environnement constamment adapté aux besoins des élèves, notamment les EBEP bénéficiant d’un PAP, PPRE ou PPS. ",
                    block: [
                        "Edumalin propose <b>un environnement de travail adapté</b> qui favorise la différenciation des cours car ces facilitateurs sont à disposition de tous les élèves qui peuvent s’en servir, s’ils le souhaitent :"
                    ],
                },
                {
                    block: [
                        "<ul><li>oralisation des consignes,</li>" +
                        "<li>possibilité d’enregistrer ses réponses,</li>" +
                        "<li>possibilité de recourir à des astuces,</li>" +
                        "<li>soutien à l’attention comme la barre de défilement,</li>" +
                        "<li>environnement pauvre en distracteurs,</li>" +
                        "<li>Feedbacks réguliers, étayages, métacognition et encouragements,</li>" +
                        "<li>correction immédiate pour favoriser la mémorisation des bonnes réponses,…</li></ul>"
                    ],
                    icon: "/img/inclusion/aiko.svg",
                    width: "14rem",
                    height: "8rem"
                },
                {
                    block: [
                        "Dans une logique d’accessibilité universelle, Edumalin propose également des traductions de certains cours en FALC : ces cours sont fournis au fur et à mesure qu’ils sont élaborés et expérimentés avec des EBEP en situation de handicap.",
                        "Ces modalités de travail peuvent faciliter le respect des prescriptions notifiées dans <b>un PPRE</b>, <b>un PAP</b> ou dans <b>un PPS</b>. C’est une aide pour répondre aux exigences des PPS opposables en droit commun."
                    ]
                },
                {
                    block: [
                        "En outre, il est possible d’utiliser les cours fournis sur la plateforme ou de créer des cours soi-même, de les entrer sur la plateforme pour profiter des modalités d’adaptation inclusives de la plateforme. ",
                        "En effet, la fonctionnalité « créer un travail » suit un gabarit qui aide les professeurs à <b>produire des contenus respectueux des besoins spécifiques</b> de nombreux élèves. "
                    ],
                    picture: "/img/inclusion/create-work.png",
                    alternativeText: "bouton créer un travail sur l'application Edumalin",
                    maxWidth: "200px"
                },
                {
                    block: [
                        "Les outils de la plateforme permettent à tous les acteurs de l'inclusion d’avoir accès en temps réel au travail et aux résultats de l’EBEP, que ce soit :"+
                        "<ul><li>le coordonnateur ULIS, d’UPE2A ou les enseignants spécialisés de SEGPA,</li>" +
                        "<li>l’AESH,</li>" +
                        "<li>les enseignants,</li>" +
                        "<li>les soignants et éducateurs,</li>" +
                        "<li>la famille</li>" +
                        "<li>etc...</li></ul>",
                        "La communication et l’évaluation sont facilitées ce qui est important notamment au moment des ESS<quote><span>Réunion de l’Equipe de Suivi de Scolarisation pour la mise à jour du PPS, sur convocation de l’Enseignant référent (Cf. MDPH).</quote></span> ou de discuter de l’orientation de l’EBEP ou de la préparation des examens."
                    ],
                    icon: "/img/features/dataviz.svg",
                    width: "20rem"
                },
                {
                    level: 1,
                    title: "2. Notre Vision",
                },
                {
                    block: [
                        "En conséquence de nos observations et des échanges avec nos partenaires, nous pouvons résumer notre vision en ces termes : nous proposons aux enseignants un outil qui les invite à mettre en pratique la différenciation en fonction de leur contexte d’exercice, <b>depuis une logique d'inclusion vers une approche d’accessibilité universelle.</b>",
                        "L’enseignant ordinaire ou spécialisé peut <b>choisir</b> comment il met en œuvre les adaptations en fonction de son public, de sa progression pédagogique et de sa manière d’utiliser les contenus adaptés en FALC, s’il en dispose.",
                        "L’élève à BEP apprend à <b>mobiliser</b> les adaptations, soit en suivant les choix que son enseignant a faits pour lui, soit en choisissant lui-même le recours à des aides les plus adaptées à sa manière de compenser ses difficultés. Ainsi l’élève apprend-il <b>les stratégies de compensation</b> de sa difficulté ou de son trouble ce qui le place en situation de réussite. Il devient de plus en plus <b>autonome</b> et conscientise quelles adaptations il doit demander pour rendre le travail accessible, s’il ne l’est pas a priori.",
                        "Cela favorise <b>l’autodétermination</b> mais aussi la <b>pair-aidance</b>, puisque les EBEP peuvent travailler les mêmes contenus que leurs camarades de classe. "
                    ],
                },
                {
                    size: "20px",
                    table: {
                        columns: [
                            "Faire travailler tous les élèves ensemble selon des modalités au choix des professeurs", "Valoriser les compétences de chaque élève malgré ses troubles et en permettant la compensation"
                        ],
                        rows: [
                            ["L’inclusion : choisir des adaptations personnalisées pour des groupes d'élèves.", "Travailler l’autonomie et l’autodétermination : choix des adaptations à mobiliser sur la plateforme, astuces et versions FALC avec imprimables, si besoin."],
                            ["L’accessibilité universelle : prévoir un contenu accessible à l'usage de tous.", "Donner à l'élève les clefs pour entrer dans une démarche d'auto-évaluation et métacognitive."],
                            ["Bénéficier en continu des fonctionnalités d'adaptation des supports, FALC, TG Inclumalin,... en fonction de situations ponctuelles.", "Favoriser la communication entre les acteurs de l’inclusion."],
                            ["Evaluer en temps réel ce que produit un EBEP en lien avec les professeurs, les coordonnateur(trices) ULIS ou les AESH et éducateurs (trices).", "La préparation des examens et des oraux."]
                        ]
                    },
                    customClass: "no-grid"
                },
                {
                    level: 1,
                    title: "3.	Notre outil supplémentaire FALC : plateforme et imprimables."
                },
                {
                    level: 2,
                    title: "Une traduction proposée aux enseignants à destination des élèves en situation de handicap mais pas que.",
                    block: [
                        "Les élèves ayant un fonctionnement entravé de leur intelligence du fait de troubles, de handicaps, d'atypies ou de carences en communication, peinent à lire les cours, à les mémoriser et à répondre à des tâches scolaires. La taille du texte, la police de caractère, les espacements entre les mots, la manière de rédiger font obstacle à <b>la compréhension</b> mais aussi à <b>la participation sociale et scolaire</b> de ces élèves dans leur classe de référence. ",
                        "Edumalin propose des parcours en version FALC qui peuvent être utilisés en l’état ou retravaillés par les enseignants avec leur public d’élèves en situation de handicap, selon leurs besoins",
                    ],
                },
                {
                    level: 2,
                    title: "Des cours en FALC plus longs mais modulables pour ne pas faire obstacle aux poursuites d’études de tous les élèves.",
                    block: [
                        "Dans les contenus en FALC, certains principes sont suivis de manière à rendre les cours <b>accessibles</b> à ces élèves mais aussi à des élèves ayant besoin d’alléger la tâche pour diverses raisons.",
                        "Edumalin propose des parcours en version FALC qui peuvent être utilisés en l’état ou retravaillés par les enseignants avec leur public d’élèves en situation de handicap, selon leurs besoins",
                    ],
                },
                {
                    block: [
                        "Il s’agit de contenus avec <b>un vocabulaire plus simple</b> mais qui ne font pas l’économie des mots importants ou des mots-clefs pour la poursuite d’études. Ces mots « compliqués » sont définis immédiatement dans le corps du cours et rassemblés le plus souvent possible dans <b>un glossaire</b>. En revanche, on diminue le nombre de substituts ou d’inférences, chaque fois que c'est possible.",
                    ],
                    picture: "/img/inclusion/cours-falc-ce2.png",
                    alternativeText: "exemple de cours falc",
                    maxWidth: "100%",
                    customClass: "flexColumn"
                },
                {
                    block: [
                        "Un travail plus approfondi sur les prérequis est mené mais dans l’objectif aussi de ne pas alourdir le cours : cela se traduit par la mise en page, le passage à la ligne et la séparation des paragraphes. En effet, les cours en FALC sont <b>plus longs</b> que les cours ordinaires sur la plateforme mais libre à l’enseignant de modifier <b>en supprimant certaines parties, très facilement</b> à partir de son tableau de bord. Il nous est cependant apparu important de fournir <b>des cours en FALC le plus complet possible</b> afin que ce soit l’enseignant et/ou l’élève qui puissent déterminer ensemble où ils posent la limite. ",
                    ]
                },
                {
                    level: 2,
                    title: "Des parcours testés par des élèves à BEP.",
                    block: [
                        "Les leçons de la plateforme ordinaire sont retravaillées par l’enseignante spécialisée titulaire du CAPPEI membre d’Edumalin et traduites de manière à suivre <b>un raisonnement qui part davantage du concret</b> ou <b>des savoirs de base sur le sujet</b>. Des éléments <b>visuels</b> sont ajoutés parfois pour soutenir le sens.",
                        "Une fois ce travail effectué, les parcours sont soumis à des élèves à BEP qui acceptent de tester la plateforme et/ou les imprimables. Ces <b>expérimentations</b> sont menées parfois dans des dispositifs ou des classes en coopération avec l’enseignant responsable des élèves, soit en individuel avec l’accord des familles. Nous sommes très reconnaissants à tous <b>les élèves testeurs</b> du temps qu’ils nous accordent pour faire ces devoirs et pour prendre le temps d'échanger avec nous sur ce qu’ils ont appris pour que nous puissions améliorer la version proposée.",
                        "A l’issue du test, des corrections sont apportées à la version initiale."
                    ],
                },
                {
                    level: 2,
                    title: "Les TG Inclumalin et les documents déjà adaptés à imprimer.",
                    block: [
                        "Pour de nombreux parcours en FALC, une TG inclumalin est <b>conçue par l'enseignante spécialisée</b> chargée d’inclusion d’Edumalin à destination des élèves les plus empêchés.",
                        "L’ensemble du parcours (Mémoriser, Tâche guidée Inclumalin, Tâche guidée, QCM) est aussi fourni <b>en imprimables à télécharger</b>.",
                        "Ces documents peuvent être directement imprimés et distribués aux élèves : <b>ils sont alors déjà adaptés</b>, en Arial 14 par défaut, avec des adaptations supplémentaires comme <b>les corrections intégrées</b> ou <b>les indices renforcés</b>.Il est toutefois <b>possible de les personnaliser</b> avant de les imprimer car ils sont fournis au format « traitement de texte »."
                    ],
                    picture: "/img/inclusion/download-feature.png",
                    alternativeText: "aperçu des boutons de téléchargement des cours",
                    maxWidth: "250px"
                },
                {
                    level: 1,
                    title: "4.	A l’issue des tests : les cas d’usage.",
                    block: [
                        "A l’issue des tests, les élèves sont interrogés sur leur expérience avec Edumalin. <b>Les cas d’usage</b> sont compilés avec les autres témoignages de professeurs et chefs d'établissement, et <b>accessibles sur le site internet d'Edumalin</b>. <a href='/cas-usages-inclusion'>Ces cas d'usage</a> sont également fournis à tous les professeurs lors de chaque déploiement de la plateforme.",
                        "Ils ont pour but de faire connaître les possibilités offertes par la plateforme et les versions FALC :"+
                        "<ul><li>en matière d’inclusion pour <b>accompagner des sous-groupes d’élèves identifiés par rapport à un besoin</b></li>" +
                        "<li>ou en matière d’accessibilité universelle, <b>pour prendre en charge une classe hétérogène</b> avec des EBEP à profils variés. </li></ul>"
                    ]
                },
                {
                    table: {
                        columns: [
                            "Cas d'un EBEP Multidys- : il parvient à rédiger des réponses complètes", "« le guidage pas à pas lui permet de montrer à ses professeurs qu’il connait bien chaque étape du raisonnement et qu’il ne trouve donc pas la bonne réponse par chance. »"
                        ],
                        rows: [
                            ["Cas d'un EBEP Trouble de l’attention : l'oralisation des contenus pour se concentrer", "« la lecture à voix haute des consignes, non pas pour réussir à lire mais pour mieux se concentrer »"],
                            ["Cas d'un EBEP Troubles des Fonctions Cognitives : la compréhension à son rythme", "« étudier les chapitres d’inclusion à son rythme, en temps de regroupement ULIS avec la coordonnatrice, et recevoir des évaluations qui sont partagées immédiatement à ses professeurs d’inclusion »"],
                            ["Cas d'un projet collectif : pair-aidance - dans le cadre d’une UEE", "« C’est en équipe que les élèves vont tenter de réaliser le chapitre entier de 6ème : Mémoriser, Tâche guidée, QCM. Le temps dévolu à cette activité prendra plusieurs heures réparties sur toute la semaine »"]
                        ]
                    },
                    customClass: "title-left"
                },
                {
                    level: 1,
                    title: "5.	Références",
                },
                {
                    level: 2,
                    title: "Textes Officiels",
                },
                {
                    level: 3,
                    title: "Des données sur l’évolution du système éducatif en matière d’inclusion :",
                    block: [
                        "<a href='https://www.education.gouv.fr/cid111136/depuis‐la‐loi‐de‐2005‐la‐scolarisation‐des‐enfants‐ en‐situation‐de‐handicap‐a‐tres‐fortement‐progresse.html' target='_blank' rel='noreferrer' class='open-in-new-tab'>Note d’information n°36, décembre 2016 :</a>",
                        "« Depuis la loi de 2005, la scolarisation des enfants en situation de handicap a très fortement progressé ». La scolarisation en milieu ordinaire des élèves en situation de handicap a augmenté de 80 % en neuf ans. En 2015, les élèves fréquentent plus souvent une classe ordinaire qu’en 2006. Dans le second degré, les effectifs ont été multipliés par 2,7 ; cette hausse s’est aussi accompagnée d’une très forte augmentation du nombre d’élèves scolarisés en ULIS. L’accompagnement individualisé a plus que doublé et concerne aujourd’hui 39 % des élèves du premier degré et 18 % de ceux du second degré.",
                        "Entre 2006 et 2018, la scolarisation en milieu ordinaire a augmenté de 117 %. Cette scolarisation en milieu ordinaire a été favorisée par la progression de l’accompagnement individualisé qui a été multiplié par trois (28 108 en 2006 et 92 248 en 2018) sur la même période. En 2018, il concerne 35 % des élèves du 1er degré et 17 % des élèves du 2nd degré.",
                        "<a href='https://www.monparcourshandicap.gouv.fr/actualite/journee-de-leducation-2023-quelles-avancees-pour-les-eleves-handicapes' target='_blank' rel='noreferrer' class='open-in-new-tab'>Les principaux chiffres de la rentrée 2022 :</a>",
                        "<ul><li>430 000 élèves en situation de handicap accueillis dans les établissements scolaires ordinaires. Ceci correspond à une augmentation de 34 % depuis 2017.</li>" +
                        "<li>67 000 élèves scolarisés en établissement médico-social ou hospitalier.</li>" +
                        "<li>132 000 accompagnants des élèves en situation de handicap (AESH).</li>" +
                        "<li>Un budget dédié à l’école inclusive en augmentation de 200 millions d’euros à la rentrée 2022 (une hausse de 66 % depuis 2017).</li></ul>",
                        "<a href='http://www.senat.fr/rap/a22-120-31/a22-120-312.html' target='_blank' rel='noreferrer' class='open-in-new-tab'>Projet de loi de finances pour 2023 : Enseignement scolaire :</a>",
                        "Malgré des prévisions démographiques à la baisse, en lien avec le déclin de la natalité, les projections font état d'un besoin croissant en ULIS : + 2 % par an (1 100 élèves au moins à accueillir en plus chaque année)."
                    ],
                },
                {
                    level: 3,
                    title: "Les modalités inclusives institutionnelles : PPRE, PAI, PAP, PPS, Cap’école inclusive ?",
                    links: [
                        {
                            text: "PPRE, PAI, PAP, PPS : En quoi consistent les différentes possibilités d'appui à la scolarisation ?",
                            link: "https://www.monparcourshandicap.gouv.fr/scolarite/ppre-pai-pap-pps-en-quoi-consistent-les-differentes-possibilites-dappui-la-scolarisation"
                        },
                        {
                            text: "Mettre en œuvre un plan d'accompagnement personnalisé",
                            link: "https://eduscol.education.fr/1214/mettre-en-oeuvre-un-plan-d-accompagnement-personnalise"
                        },
                        {
                            text: "CANOPE, Cap’école inclusive ",
                            link: "https://www.reseau-canope.fr/cap-ecole-inclusive"
                        },
                    ]
                },
                {
                    level: 3,
                    title: "Refondation de l’éducation prioritaire",
                    links: [
                        {
                            text: "Bulletin officiel sur education.gouv.fr",
                            link: "https://www.education.gouv.fr/bo/14/Hebdo23/MENE1412775C.htm"
                        }
                    ]
                },
                {
                    level: 2,
                    title: "Bibliographie succincte",
                    links: [
                        {
                            text: "M. Mazeau et A. Pouhet : Dans le cerveau de mon enfant ; tout le développement cognitif de l’enfant de 0-6 ans",
                            link: "https://www.decitre.fr/livres/dans-le-cerveau-de-mon-enfant-9782226473189.html"
                        },
                        {
                            text: "M. Mazeau et A. Pouhet : Bien apprendre à l’école ; lire, écrire, compter : ce qui change avec les neurosciences (6-8 ans)",
                            link: "https://www.decitre.fr/livres/bien-apprendre-a-l-ecole-9782705805944.html"
                        },
                        {
                            text: "G. Loty et M. Mazeau : DYS - Outils et adaptations dans ma classe - Cycles 2 et 3",
                            link: "https://www.editions-retz.com/pedagogie/domaines-transversaux/dys-outils-et-adaptations-dans-ma-classe-cycles-2-et-3-9782725643144.html"
                        },
                        {
                            text: "C. Valette : Guide pratique « Enseigner à tous les élèves – les Besoins Educatifs Particuliers ».",
                            link: "https://www.ebla-editions.fr/ulis-college/272-guide-pratique-enseigner-a-tous-les-eleves-bep.html"
                        },
                        {
                            text: "L. Bergeron, N. Rousseau & M. Leclerc (2011): La pédagogie universelle : au cœur de la planification de l’inclusion scolaire. Éducation et francophonie, 39(2), 87–104.",
                            link: "https://id.erudit.org/iderudit/1007729ar"
                        },
                    ]
                },
                {
                    level: 2,
                    title: "FALC",
                    links: [
                        {
                            text: "Easy to read - inclusion-europe.eu",
                            link: "https://www.inclusion-europe.eu/easy-to-read/"
                        },
                        {
                            text: "Association UNAPEI",
                            link: "https://www.unapei.org/combat/accessibilite/"
                        },
                        {
                            text: "Diacquenod, C., & Santi, F. (2018). La mise en œuvre du langage facile à lire et à comprendre (FALC): enjeux, défis et perspectives. Revue suisse de pédagogie spécialisée, 2, 29-35.",
                            link: "https://ojs.szh.ch/revue/article/view/48"
                        },
                        {
                            text: "Canut, E., Delahaie, J., & Husianycia, M. (2020). Vous avez dit FALC? Pour une adaptation linguistique des textes destinés aux migrants nouvellement arrivés. Langage & société, 171(3), 171-201.",
                            link: "https://www.cairn.info/revue-langage-et-societe-2020-3-page-171.htm"
                        },
                    ]
                },
                {
                    level: 2,
                    title: "Partenaire",
                    imageLink: "https://www.ffdys.com/",
                    imageSource: "/img/inclusion/ffdys-icon.webp",
                    alternativeText: "Fédération Française des dys",
                    maxWidth: "250px"
                },
            ]
        }
    ]
};