import { render, staticRenderFns } from "./EduVersion.vue?vue&type=template&id=4b7fd451&"
import script from "./EduVersion.vue?vue&type=script&lang=ts&"
export * from "./EduVersion.vue?vue&type=script&lang=ts&"
import style0 from "./EduVersion.vue?vue&type=style&index=0&id=4b7fd451&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports