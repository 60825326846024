import Vue from 'vue'
import App from './App.vue'
import store from './store'
import getConfig from "./config/site-config";
import cms, {getEmptyRouter} from "@snark/cms";
import VModal from 'vue-js-modal';
import VueGtag from "vue-gtag";
import moment from "moment-timezone";
moment.tz.setDefault('Europe/France');
moment.locale('fr');

(async function() {
    // --- Router
    const router = getEmptyRouter({
        scroll: {
            smooth: true,
            hashOffset: 79
        }
    });

    // --- CMS
    Vue.use(cms, {
        router,
        store,
        configuration: await getConfig()
    });

    // Modals
    Vue.use(VModal, {
        dynamicDefaults: {
            height: 'auto',
            adaptive: true,
            scrollable: true,
            focusTrap: true,
            classes: "dialog"
        }
    });

    // --- Analytics
    if(process.env.VUE_APP_ENV !== "development") {
        Vue.use(VueGtag, {
            config: {id: process.env.VUE_APP_ANALYTICS_ID}
        }, router);
    }

    Vue.config.productionTip = false;

    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app');
})();
