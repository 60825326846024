import { CMSConfiguration } from "@snark/cms";
import EduFooter from "@/components/EduFooter.vue";
import EduWelcome from "@/components/EduWelcome.vue";
import EduVersions from "@/components/EduVersions.vue";
import EduTwoColumns from "@/components/EduTwoColumns.vue";
import EduExplanation from "@/components/EduExplanation.vue";
import EduPartners from "@/components/EduPartners.vue";
import EduText from "@/components/EduText.vue";
import EduCards from "@/components/EduCards.vue";
import EduMiniSimpleCard from "@/components/EduMiniSimpleCard.vue";
import EduScreens from "@/components/EduScreens.vue";
import EduFeatures from "@/components/EduFeatures.vue";
import EduUseCases from "@/components/EduUseCases.vue";
import EduTne from "@/components/EduTne.vue";
import EduUseCasesFalc from "@/components/EduUseCasesFalc.vue";
import EduTutos from "@/components/EduTutos.vue";
import EduFaqs from "@/components/EduFaqs.vue";
import EduContact from "@/components/EduContact.vue";
import EduOnlyTitle from "@/components/EduOnlyTitle.vue";
import EduHomeModale from "@/components/EduHomeModale.vue";
import EduTeam from "@/components/EduTeam.vue";
import EduDates from "@/components/EduDates.vue";
import EduPoints from "@/components/EduPoints.vue";
import EduTestimonies from "@/components/EduTestimonies.vue";
import manifeste from "./manifesto";
import falcScolaire from "./falc_scolaire";
import science from "./science";
import EduEvents from "@/components/EduEvents.vue";
import EduVideo from "@/components/EduVideo.vue";
import webinaires from "./webinaries";
import EduTweets from "@/components/EduTweets.vue";
import EduRgpd from "@/components/EduRgpd.vue";
import EduCgps from "@/components/EduCgps.vue";

const config: CMSConfiguration = {
    layouts: {
        main: {
            _component: "cms-simple-layout",
            slots: {
                header: {
                    _component: "cms-simple-header",
                    logo: "/img/logo.svg",
                    logoRoute: { name: "home" },
                    menu: "mainMenu"
                },
                footer: {
                    _component: EduFooter,
                    copyright: "Copyrights© 2021 - Tous droits réservés"
                }
            }
        }
    },
    pageLayouts: {
        horizontalLayers: {
            _component: "cms-horizontal-layers"
        }
    },
    navigation: {
        mainMenu: [
            { name: "plateforme", label: "La plateforme" },
            { name: "usages", label: "Vos usages", dropdown: { menu: "usagesMenu" } },
            { name: "tne", label: "TNE" },
            { name: "science", label: "Côté science" },
            { name: "inclusion", label: "Côté inclusion", dropdown: { menu: "inclusionMenu" } },
            { name: "apropos", label: "A propos", dropdown: { menu: "aboutMenu" } },
            { name: "contact", label: "Contact" }
        ],
        usagesMenu: [
            { name: "usages_cas", label: "Cas d'usages" },
            { name: "faq", label: "FAQ" },
            { name: "support", label: "Contact support" }
        ],
        inclusionMenu: [
            { name: "falcScolaire", label: "Le FALC scolaire Edumalin" },
            { name: "usage_cas_inclusion", label: "Cas d'usages inclusion" },
        ],
        aboutMenu: [
            { name: "equipe", label: "L'équipe" },
            { name: "principes", label: "Les 7 principes Edumalin" },
            { name: "manifeste", label: "Le manifeste" },
            { name: "actus", label: "Les actus" },
            { name: "temoignages", label: "Les témoignages" }
        ]
    },
    pages: {
        home: {
            _path: "/",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduHomeModale,
                    _name: "modale",
                    ctaLabel: "Réforme de la classe de 6e de Septembre 2023 avec Edumalin : la stratégie académique de Paris",
                    title: "Réforme de la classe de 6e avec Edumalin",
                    subtitle: "La stratégie académique de Paris",
                    texts: [
                        "Après plusieurs années d’expérimentation dans les collèges parisiens de notre plateforme, dédiée à l’apprendre à apprendre et à l’approche inclusive, <b>la ville et l’académie de Paris</b> ont mis conjointement à disposition les ressources Edumalin pour les <b>4 niveaux de tous les collèges</b>.",
                        "<b>M. Sébastien TAVERGNE, DAASEN, et M. Philippe TAILLARD, DANE</b> ont convié les chefs d’établissement à une réunion en juin 2023 pour les en informer et leur communiquer les grandes lignes de la <b>stratégie académique</b> qui sera mise en œuvre dès la <b>rentrée de septembre 2023.",
                        "<a href='https://bbb-adm-scalelite.visio.education.fr/playback/presentation/2.3/93d376d8f286ddc6cd0b675cd7aad79ec28c7b45-1688039493252' target='_blank' rel='noreferrer' class='open-in-new-tab'>Regarder cette réunion</a>",
                        "Dans le cadre de la réforme de la classe de 6<sup>e</sup></b>, l’académie de Paris entend encore <b>davantage s’appuyer sur Edumalin, en particulier pour les usages pédagogiques</b> suivants :",
                        "<ul><li><b>Soutien et renforcement en Mathématiques et Français dans la nouvelle 6<sup>e</sup></b></li>" +
                        "<li><b>Devoirs Faits, tout particulièrement dans la nouvelle 6<sup>e</sup></b>" +
                        "<li><b>Entraînement en classe</b> (pratique guidée de l’enseignement explicite – élève avec l’aide ponctuelle du professeur)</li>" +
                        "<li><b>Travail personnel à la maison</b> (pratique autonome de l’enseignement explicite)</li>" +
                        "<li><b>Entraînement des élèves en autonomie</b> (35% des usages en 2022-2023)</li></ul>",
                        "L’académie de Paris souligne la pertinence des contenus Edumalin, notamment <b>le renforcement des fondamentaux et de l’autonomie</b>, rendu possible <b>grâce aux ressources Edumalin conçues sur les grands principes de</b> <a href='https://www.reseau-canope.fr/fileadmin/user_upload/Projets/conseil_scientifique_education_nationale/CSEN_Synthese_enseignement-explicite_juin2022.pdf' target='_blank' rel='noreferrer' class='open-in-new-tab'>l’enseignement explicite</a>, et <b>grâce à toutes les modalités d’utilisation de la plateforme</b> : en classe, par les élèves en autonomie, par les professeurs d’une discipline qui veulent suivre leurs élèves, et par les professeurs d’autres disciplines qui encadrent les élèves sur un temps de Devoirs Faits ou sur un temps d’accompagnement. Sur la plateforme, les professeurs disposent de centaines de ressources développées selon des objectifs pédagogiques clairs (Se tester et Mémoriser, S’exercer), qu’ils peuvent <b>utiliser telles quelles, éditer en tous points, ou créer ex nihilo.</b>",
                        "Le taux d’usage significatif d’Edumalin par les professeurs et par les élèves ces quatre dernières années témoigne du fait que la plateforme semble répondre à un besoin véritable.  Les expérimentations démontrent que <b>les élèves s’approprient complètement l’outil</b> et que les niveaux d’analyse permettent aux enseignants <b>d’accompagner de manière beaucoup plus fine et beaucoup plus individualisée les élèves</b> dans leur progression.",
                        "Les ressources Edumalin associent systématiquement une activité (le QUOI : leçon, exercice, annale) avec la méthode pour la réaliser (le COMMENT). Cette combinaison originale et progressive permet de réduire au minimum l’implicite et <b>de favoriser l'identification, la répétition et l'acquisition de stratégies d'apprentissage efficaces</b> dans toutes les matières et pour tous les élèves. Cette construction de <b>l’apprendre à apprendre</b> et de l’autonomie est essentielle et alignée avec les <a href='https://www.education.gouv.fr/college-ce-qui-change-pour-les-eleves-de-6e-la-rentree-2023-344017' target='_blank' rel='noreferrer' class='open-in-new-tab'>objectifs ministériels</a> : « <i>&Agrave; l’entrée en 6<sup>e</sup>, 27% des élèves n’ont pas le niveau attendu en français, près d’un tiers en mathématiques. Le ministre a pour ambition de construire une nouvelle 6e qui puisse assurer à chaque élève, dans la continuité des actions déjà engagées à l’école primaire, de maîtriser les savoirs fondamentaux indispensables à la suite de sa scolarité et ce afin de remédier aux difficultés scolaires et de donner aux élèves les mêmes chances de réussite.</i> »",
                        "<b>La stratégie académique de Paris quant à la réforme de la classe de 6e repose pour partie sur Edumalin et sera déployée dès septembre 2023</b>. En plus de la mise à disposition de la plateforme en mathématiques, français, histoire-géographie et EMC, les établissements bénéficieront d’un <b>accompagnement académique de la DANE</b> via les référents de l’ENT, qui deviendront <b>« Référents ENT et Edumalin » à la rentrée</b>. Ces référents Edumalin seront formés puis animeront des FIL simplifiées dédiées aux usages pédagogiques possibles en appui sur Edumalin au sein de leur établissement. "
                    ]
                },
                {
                    _component: EduWelcome,
                    _name: "welcome",
                    title: "Parce qu’apprendre est aussi au programme",
                    text: "Découvrez AIKO, l’assistant pédagogique virtuel hébergé sur notre plateforme, qui <b>explicite</b>" +
                        " les méthodes de travail pour guider et suivre chacun, chacune lors de son temps de travail" +
                        " autonome (en classe, lors de Devoirs Faits, en AP…).\n" +
                        "Centrée sur l’apprendre à apprendre, notre approche pédagogique exclusive et originale propose" +
                        " de <b>systématiquement combiner un contenu</b> (le QUOI : support à mémoriser, exercice" +
                        " simple, tâche plus complexe, etc..) <b>avec une méthode</b>, une procédure explicite pour" +
                        " le réaliser (le COMMENT).\n" +
                        "C’est cette combinaison systématique qui va permettre à un jeune de" +
                        " <b>s’approprier le contenu</b> prévu mais également de <b>construire des stratégies d’apprentissage efficaces</b>" +
                        " (apprentissages auto-régulés) et devenir un apprenant tout au long de sa vie...",
                    quote: "...alors merci de nous rejoindre.",
                    cta: "Réserver une démo",
                    ctaLink: "/demo",
                    picture: "/img/illus-home-1.svg"
                },
                {
                    _component: EduVideo,
                    _name: "video",
                    title: "Présentation d'Edumalin",
                    video: "https://www.youtube-nocookie.com/embed/Ucn3bSmioHQ?si=PnVdLjXNrGGjo0io&amp&rel=0"
                },
                {
                    _component: EduVersions,
                    _name: "versions",
                    title: "Les versions d'Edumalin",
                    subtitle: "Découvrez ci-dessous les <b>différentes versions d’Edumalin</b> et contactez-nous pour en savoir plus"
                },
                {
                    _component: EduEvents,
                    _name: "events",
                    title: "Les webinaires Edumalin",
                    subtitle: "Formations, webconférences, évènements : <b>retrouvez-nous et échangeons</b>.",
                    events: "webinaires",
                    count: 4
                },
                {
                    _component: EduTwoColumns,
                    _name: "demarche",
                    title: "Notre démarche",
                    subtitle: "Au carrefour de la pédagogie, de la technologie et des recherches scientifiques.",
                    columns: [
                        {
                            name: "Démarche fondée sur les preuves",
                            category: "démarche",
                            texts: [
                                "Nous sommes convaincus que <b>le développement de la compétence d’autorégulation de l’apprentissage doit être alimenté par les données issues de la recherche combinées à des données d’apprentissage en contexte.</b>",
                                "En empruntant cette voie, notre objectif est <b>d’optimiser et repenser l’apprentissage en consolidant fortement la place de l’enseignant, du formateur au cœur de la pédagogie</b> " +
                                "<quote><span>Pasquinelli, E. (2013). Slippery slopes. Some considerations for favoring a good marriage between education and the science of the mind–brain–behavior, and forestalling the risks. Trends in Neuroscience and Education, 2(3–4), 111–121.</span></quote> ."
                            ],
                            picture: "/img/illus-demarche.png"
                        },
                        {
                            name: "Expliciter, Guider et Suivre",
                            category: "méthode",
                            texts: [
                                "Pour soutenir le <b>développement de stratégies d’apprentissage efficaces</b>, nous avons créé " +
                                "une plateforme pédagogique et numérique innovante qui intègre le modèle à 3 étapes de " +
                                "l’Apprentissage AutoRégulé " +
                                "<quote><span>Cosnefroy, L. (2011). L’apprentissage autorégulé. Entre cognition et motivation. Grenoble, France: Presses universitaires de Grenoble.</span></quote> " +
                                "<quote><span>Quigley, A., Muijs, D., Stringer, E. (2020). Seven recommendations for teaching self-regulated learning & metacognition. Guidance Report for Education Endowment Foundation.</span></quote>" +
                                " : expliciter, guider et suivre."
                            ],
                            picture: "/img/illus-methode2.png"
                        }
                    ],
                    cta: "Visionner&nbsp;notre&nbsp;webinaire de présentation",
                    video: "https://www.youtube.com/embed/H97_xAczyM8"
                },
                {
                    _component: EduExplanation,
                    _name: "explanation",
                    title: "Notre outil",
                    steps: [
                        {
                            title: "Expliciter",
                            color: "red",
                            position: "bottom",
                            texts: [
                                "En tant que professeur, je «&nbsp;m’assois&nbsp;» à la place de l’élève à qui je vais donner cette tâche et explicite chaque étape du raisonnement qui est le mien pour la réaliser.",
                                "En associant le support et ces étapes explicites : je crée le guidage d’AIKO."
                            ],
                            picture: "/img/illus-home-2.svg"
                        },
                        {
                            title: "Guider",
                            color: "blue",
                            position: "middle",
                            texts: [
                                "En tant qu’élève, je prends appui sur les étapes successives indiquées par AIKO pour réaliser méthodiquement et à ma vitesse l’activité proposée. Chaque étape donne lieu à une correction, à un feed-back pour un apprentissage de bien meilleure qualité."
                            ],
                            picture: "/img/illus-home-3.svg"
                        },
                        {
                            title: "Suivre",
                            color: "green",
                            position: "top",
                            texts: [
                                "Professeur ou élève, j’ai accès à 2 types de données :",
                                "- Le résultat à l’activité",
                                "- La démarche pour arriver à ce résultat …Et comprendre très exactement ou cela a pu bloquer !"
                            ],
                            picture: "/img/illus-home-4.svg"
                        }
                    ]
                },
                {
                    _component: EduPartners,
                    _name: "confiance",
                    title: "Ils nous font confiance",
                    partnersData: true
                },
                {
                    _component: EduTestimonies,
                    _name: "testimonies",
                    random: true
                }
            ]
        },
        plateforme: {
            _path: "/plateforme",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduCards,
                    _name: "access",
                    title: " Accéder à Edumalin",
                    subtitle: "Rien de plus simple, <b>3 cas de figure</b>",
                    cards: [
                        {
                            color: "blue",
                            hilight: "green",
                            title: "Vous êtes un établissement scolaire rattaché au GAR ?",
                            texts: [
                                "<b>Vous retrouverez Edumalin dans le Médiacentre de votre ENT.</b>",
                                "Cet accès témoigne également de tout le sérieux de notre équipe quant à la protection et au stockage des données personnelles de nos utilisateurs."
                            ]
                        },
                        {
                            color: "blue",
                            hilight: "green",
                            title: "Pas de GAR mais un ENT ou un LMS ?",
                            texts: [
                                "<b>Nous étudions l’opportunité de créer un connecteur avec votre fournisseur ENT pour déployer via celui-ci.</b>",
                                "Cette étude et ce déploiement sont également réalisés pour toute structure de formation (CFA, etc.) souhaitant déployer/intégrer Edumalin à son propre LMS."
                            ]
                        },
                        {
                            color: "blue",
                            hilight: "green",
                            title: "Aucune structure numérique existante ?",
                            texts: [
                                "<b>Nous déployons alors Edumalin directement</b> en vous fournissant des codes d’accès pour chacun des utilisateurs de la plateforme. Simple, rapide, sécurisé et efficace."
                            ]
                        }
                    ]
                },
                {
                    _component: EduMiniSimpleCard,
                    _name: "supports",
                    color: "red",
                    picture: "/img/illus-screens.svg",
                    text: "Accessible sur tous types de supports :\n" +
                        "ordinateur, tablette et téléphone"
                },
                {
                    _component: EduCards,
                    _name: "parcours",
                    title: " Parcours et Modules Edumalin",
                    cards: [
                        {
                            color: "green",
                            hilight: "blue",
                            title: "Transversalité",
                            texts: [
                                "Chez Edumalin, <b>nous développons une approche transversale et commune</b> à tous les niveaux de scolarité ou de formation."
                            ]
                        },
                        {
                            color: "green",
                            hilight: "blue",
                            title: "Consolidation",
                            texts: [
                                "C’est cette récurrence et stabilité dans l’approche qui permet <b>d’identifier et de consolider les stratégies d’apprentissage efficaces</b> (cf. à propos) : nous parlons ici de consolidation (4ème pilier de l’apprentissage)."
                            ]
                        },
                        {
                            color: "green",
                            hilight: "blue",
                            title: "Scénarisation",
                            texts: [
                                "Nos parcours articulent en général 3 types de modules (des variantes existent à certains niveaux) pour <b>offrir une approche scénarisée</b>, attractive et dont le sens est perceptible dans chaque chapitre traité."
                            ]
                        }
                    ]
                },
                {
                    _component: EduScreens,
                    _name: "screens",
                    screens: [
                        {
                            picture: "/img/app-screen/screen-1.png",
                            title: "Le module « Mémoriser »",
                            text: "Le module « Mémoriser » associe une synthèse, un support de cours, des informations-clés destinés à être mémorisés et une méthode pour le faire. Cette association entre le cours et le guidage (composé sur la base de stratégies de testing et recherche active d’information) permet à l’élève de s’approprier les informations en comprenant véritablement les points clés du cours et les objectifs d’acquisition du contenu.",
                            plus: "Le + : la robustesse de l’effet de récupération en mémoire comme méthode de révision."
                        },
                        {
                            picture: "/img/app-screen/screen-2.png",
                            title: "Le module « Tâche guidée »",
                            text: "Le module « Tâche Guidée » associe un énoncé d’un exercice impliquant un raisonnement complexe (lecture, compréhension de la consigne, appui sur des connaissances théoriques au bon moment, élaboration et exécution d’une démarche, d’un raisonnement…) avec un guidage sous forme d’étapes de raisonnement. C’est cette explicitation et cette association qui favorise la construction d’une pensée algorithmique commune à toutes les matières.",
                            plus: "Le + : côté élève, ce passage à l’action sous forme décortiquée permet de mobiliser les connaissances pas à pas, à son rythme tel un pianiste débutant qui découvre les gammes… Avant de les automatiser et de les exécuter à grande vitesse.\n" +
                                "Attention, escamoter la 1ère étape peut s’avérer  dangereux pour la suite des apprentissages."
                        },
                        {
                            picture: "/img/app-screen/screen-3.png",
                            title: "Le module « QCM »",
                            text: "Le module « QCM », de conception plus classique mais notoirement efficace, vient renforcer l’ancrage par récupération et permet la vérification objective du degré d’acquisition des connaissances avec une correction personnalisée. L’objectif est de permettre à l’élève de mémoriser son parcours et de se montrer à lui-même (ainsi qu’à son professeur, le cas échéant) qu’il progresse : il semble désormais qu’il y ait consensus sur le fait que la réussite est un moteur très puissant de la motivation.",
                            plus: "Le + : tous les QCM Edumalin sont éditables par les professeurs, un gain de temps phénoménal."
                        }
                    ]
                },
                {
                    _component: EduFeatures,
                    _name: "features",
                    title: "Les fonctionnalités essentielles",
                    teachers: {
                        title: "Côté professeur",
                        features: [
                            {
                                icon: "/img/features/biblio.svg",
                                text: "<b>Bibliothèques de ressources prêtes à l’emploi</b> : 30’’ pour donner du travail ! Des centaines de parcours et modules directement utilisables."
                            },
                            {
                                icon: "/img/features/custom.svg",
                                text: "<b>Modules et parcours personnalisables</b> : tous ces travaux constituent votre banque de ressources adaptables et modifiables selon vos choix pédagogiques."
                            },
                            {
                                icon: "/img/features/creation.svg",
                                text: "<b>Outil de création de contenus</b> : créez vos propres modules et parcours et boostez les progrès de vos élèves."
                            },
                            {
                                icon: "/img/features/dataviz.svg",
                                text: "<b>Datavisualisation</b> : prenez connaissance de leurs résultats et visualisez chaque étape de leur raisonnement. " +
                                    "Voir ce qu’ils savent vraiment et comprendre leurs blocages n’a jamais été aussi simple ! Pour chacun (vue individuelle), pour tous (vue collective)"
                            },
                            {
                                icon: "/img/features/suivi.svg",
                                text: "<b>Suivi</b> : outil de commentaires écrits et vocaux. " +
                                    "Parce que rien ne vaut un feed-back hyper personnalisé et adapté au véritable obstacle rencontré."
                            }
                        ]
                    },
                    students: {
                        title: "Côté élève",
                        features: [
                            {
                                icon: "/img/features/faire.svg",
                                text: "<b>Faire son travail</b> : AIKO te propose les travaux donnés par les professeurs, te rappelle des travaux en cours ou inachevés ou te propose de t’entraîner en autonomie."
                            },
                            {
                                icon: "/img/features/stats.svg",
                                text: "<b>Stats et suivi</b> : ton espace te permet de voir rapidement ou tu te situes. " +
                                    "Pas de jugement, juste des infos pour te permettre de progresser... Ou te féliciter !"
                            }
                        ]
                    }
                },
            ]
        },
        usages_cas: {
            _path: "/cas-usages",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduUseCases,
                    _name: "cases",
                    title: " Cas d'usages",
                    cases: [
                        {
                            title: "Cas d’usage : LP, niveau CAP",
                            texts: [
                                "<u>Résumé :</u>",
                                "<ul><li>Une première expérimentation mesurée sur l’usage d’Edumalin a été réalisée avec des élèves (N=30) et des enseignants (N=7) au sein d’un lycée professionnel entre fin 2019 et début 2020 (4 mois).</li>" +
                                "<li>Cette mesure a été effectuée par un organisme externe à Edumalin.</li>" +
                                "<li>L’évaluation du ressenti des élèves montre des résultats très positifs.</li></ul>",
                                "Globalement, les élèves perçoivent un gain non négligeable après plusieurs semaines d’utilisation des Parcours Edumalin. Ils expriment le fait que l’application les a aidés à s’entraîner.",
                                "Nous avons synthétisé leur ressenti sur trois paramètres : utilité et bénéfice perçus de l’outil ; intérêt des indications et aides fournies ; adhésion à l’utilisation de l’outil (voir infographie ci-dessous)."
                            ],
                            galleries: [
                                { columns: 3, pictures: ["/img/usages/cas-1-1.png", "/img/usages/cas-1-2.png", "/img/usages/cas-1-3.png"] },
                                { columns: 3, pictures: [null, "/img/usages/cas-1-legend.png"] }
                            ]
                        },
                        {
                            title: "Cas d’usage : niveau Collège en Maths et Français",
                            texts: [
                                "<b>Écouter l’intégralité du Podcast : <a href='https://video.ac-paris.fr/mp4/2020-05/sapd_hd.mp4'>https://video.ac-paris.fr/mp4/2020-05/sapd_hd.mp4</a></b>",
                                "<u>Thème :</u>",
                                "Réflexions et témoignages en visioconférence au sujet de l’enseignement à distance et des usages de ressources numériques.",
                                "<u>Participants</u>",
                                "<ul><li>P.Taillard, DANE de l’Académie de Paris</li>" +
                                "<li>N.Deroo, Principal du collège G.Tillion</li>" +
                                "<li>J.Solari, Professeure de Lettres</li>" +
                                "<li>L.Bazin, Professeur de Maths</li>" +
                                "<li>F.Baritiu, Président d’Edumalin</li></ul>",
                                "<u>Résumé :</u>",
                                "<b>P.Taillard : Directeur Académique du Numérique Paris</b>",
                                "« Edumalin (…) Outil que l’on juge d’une grande qualité et d’une pertinence pédagogique avérée »",
                                "<b>N.Deroo : Principal du collège Germaine Tillion</b>",
                                "« Le constat : pas de travail personnel de nos élèves. Edumalin nous paraît être l’outil le plus pertinent pour encourager et accompagner ce travail personnel dans devoirs faits »",
                                "« Ce qui nous a séduit dans Edumalin, c’est que c’est une démarche fondamentalement pédagogique. »",
                                "« Edumalin a permis de répondre à une 1ère hypothèse : si les élèves ne travaillent pas à la maison, c’est qu’ils ne savent pas le faire à priori. Edumalin leur permet de le faire très concrètement et au quotidien »",
                                "<b>J.Solari : Professeure de Lettres</b>",
                                "« Mes usages en Français : découvrir la notion, ce que je faisais jusqu’à maintenant en présentiel. Cette méthodologie sera conservée car elle représente un gain de temps sur le temps de cours »",
                                "« Cela me permet de réfléchir très en amont aux étapes que les élèves doivent enchaîner mentalement pour parvenir à exécuter une tâche. »",
                                "« Cela nous oblige à nous mettre à la place de l’élève. »",
                                "« Étapes courtes, auto-corrigées et aucun blocage. Démarche très efficace »",
                                "« Les élèves s’en sont énormément emparés »",
                                "<b>L.Bazin : Professeur de Mathématiques</b>",
                                "« Plateforme découverte après le début du confinement et assez vite séduit par la simplicité et l’efficacité »",
                                "« Utilisation dans une séquence globale : 1) défi de construction parallélogrammes, 2) constat des notions pas maîtrisées, 3) module Edumalin dédié aux notions de symétrie non maîtrisées. »",
                                "« Amélioration/ce qui se passe en classe : changement de point de vue qui permet d’expliciter davantage pour avoir une vision plus globale »",
                                "« Retours élèves largement positifs sans aucune formation effectuée en amont. Prise en main et usage très simple. »"
                            ]
                        }
                    ]
                },
                {
                    _component: EduTutos,
                    _name: "tutos",
                    title: "Les tutos malins",
                    guide: {
                        title: "Consulter le guide d'utilisation",
                        cta: {
                            label: "Télécharger",
                            color: "red",
                            download: "/doc/edumalin-guide-utilisation-professeurs.pdf"
                        }
                    }
                },
                {
                    _component: EduWelcome,
                    _name: "demo",
                    mode: "little",
                    title: "Vous voulez en savoir plus ?",
                    text: "Quoi de mieux pour se faire une idée que de tester soi-même la plateforme.\n" +
                        "Laissez-nous vous présenter toutes les fonctionnalités et la meilleure manière d'utiliser nos outils.",
                    cta: "Réserver une démo",
                    ctaLink: "/demo",
                    picture: "/img/illus-demo.svg"
                }
            ]
        },
        tne: {
            _path: "/tactimalin",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduPartners,
                    _name: "partenaires",
                    marginTop: "margin-top: 0",
                    partners: [
                        {
                            picture: "/img/tne/logotype-rouge-bleu.jpeg",
                            alternativeText: "Logo France 2030"
                        },
                        {
                            picture: "/img/tne/logo-banque-territoire.png",
                            alternativeText: "Logo Banque des Territoires"
                        },
                        {
                            picture: "/img/tne/logo-reseau-canope.png",
                            alternativeText: "Logo Réseau Canopé"
                        },
                        {
                            picture: "/img/tne/Trousse_à_projets.png",
                            alternativeText: "Logo Trousse à projets"
                        },
                    ],
                    text: "Lauréat Ressources TNE dans le cadre de France 2030"
                },
                {
                    _component: EduTne,
                    _name: "tactiCases",
                    banner: "/img/tne/banner.png",
                    alternativeText: "Logo Tactimalin",
                    mainTitle: "Qu'est-ce que Tactimalin ?",
                    customDisplay: "none",
                    cases: [
                        {
                            texts: [
                                "<p>Edumalin, sous sa forme <a href='https://www.tactimalin.education/' target='_blank' rel='noreferrer' class='open-in-new-tab'>Tactimalin</a> (avec l’entreprise Maskott), est la solution numérique éducative la plus sélectionnée dans le cadre des <a href='https://www.education.gouv.fr/69-solutions-numeriques-educatives-et-34-entreprises-du-numerique-retenues-par-l-etat-pour-les-341393' target='_blank' rel='noreferrer' class='open-in-new-tab'>Territoires Numériques Educatifs (TNE)</a>.</p>",
                                "Il s’agit d’un appel à projets financé par le dispositif <b>France 2030</b>, porté par le <b>Réseau Canopé, le SGPI, le Ministère et la Banque des Territoires</b>, visant à déployer des <b>ressources numériques éducatives</b> au sein de 12 départements français et à destination de toutes les <b>écoles primaires, les collèges ainsi que les lycées</b>.",
                                "<b>Edumalin</b> édite des ressources basées sur l'<b>apprendre à apprendre et la construction de l'autonomie</b>. Grâce à ces ressources, chaque élève <b>construit, consolide, et automatise des stratégies d'apprentissage efficaces</b> dans toutes les matières.",
                                "<b>Tactileo</b> est une plateforme d’apprentissage numérique. Cet outil au service de la <b>différenciation pédagogique et de l’individualisation des apprentissages</b> permet de créer en quelques clics des parcours interactifs et ludiques."
                            ]
                        }
                    ]
                },
                {
                    _component: EduWelcome,
                    _name: "welcome",
                    mode: "small",
                    customMarginTop: "0",
                    text: "Vous enseignez dans un des 12 départements des TNE, <b>bénéficiez dès à présent de Tactimalin</b>, solution numérique éducative développée par Edumalin & Maskott, et <b>mise à disposition gratuitement par le Réseau Canopé</b>.",
                    cta: "Créer un compte",
                    ctaLink: "https://tne.reseau-canope.fr/inscription.html",
                    textRight: "Les Bouches-du-Rhône, le Cher, la Corse-du-Sud, le Doubs, le Finistère, la Guadeloupe, l’Hérault, l’Isère, la Vienne, les Vosges, l’Aisne et le Val d’Oise.",
                    cta2: "Accéder aux collections Tactimalin",
                    ctaLink2: "https://tne.reseau-canope.fr/outils-numeriques/solutions-numeriques-educatives.html",
                    pictureRight: "/img/tne/carte-tne.svg",
                    alternativeText: "Carte des départements TNE"
                },
                {
                    _component: EduTne,
                    _name: "cases",
                    title: "Tactimalin et sa démarche pédagogique",
                    cases: [
                        {
                            texts: [
                                "Tactimalin offre des ressources conçues pour le <b>travail personnel et la construction de l’autonomie</b>, à tous les niveaux et pour tous les élèves. Dédiés à l’<b>apprendre à apprendre</b> et basés sur les grands principes de l’<b>enseignement explicite</b>, nos parcours pédagogiques combinent systématiquement une activité, <b>le QUOI</b> (leçon, exercice…) et la méthode spécifique pour effectuer le travail, <b>le COMMENT</b> (résolution de problème, rédaction de paragraphe argumenté…).",
                                "Cette combinaison crée un <b>fil d’Ariane qui permet à chaque élève de progresser</b> dans l’activité à son rythme et de manière sécurisante.<br><b>Des données d’apprentissage accessibles en temps réel</b> vous permettent de visualiser les réussites des élèves et, surtout, l’endroit précis des raisonnements et démarches qui leur posent un problème : une <b>remédiation ultra-adaptée</b> devient possible.",
                                "En tous points <b>conformes au programme</b>, vous pouvez utiliser ces <b>contenus tels quels ou les personnaliser</b> en les améliorant avec vos choix et vos propres activités pédagogiques.<br>LMS complet, Tactimalin permet également aux professeurs de créer leurs contenus de toutes pièces, grâce à de nombreuses fonctionnalités techniques et options de scénarisation."
                            ]
                        }
                    ]
                },
                {
                    _component: EduOnlyTitle,
                    _name: "collections",
                    title: "Les collections Tactimalin",
                },
                {
                    _component: EduScreens,
                    _name: "screens",
                    customMarginTop: "45px",
                    screens: [
                        {
                            picture: "/img/tne/tactimalin-primaire.png",
                            title: "Tactimalin Primaire",
                            titleStyle: "center",
                            alignItems: "center",
                            text: "Tactimalin Primaire propose des centaines de ressources pour consolider les fondamentaux, développer des compétences transversales, découvrir et s’approprier les notions, réviser et préparer efficacement l'entrée au collège...",
                            legends: "<span>Tactimalin Primaire Fondamentaux<quote><span>Mathématiques, Français et Enseignement moral et civique.</span></quote></span><span>Tactimalin Primaire<quote><span>Anglais, Humanités numériques, Enseignements artistiques, Sciences et technologie, Education au développement durable.</span></quote></span>",
                            plus: false,
                            columnCount: 1,
                            cta2: "Primaire",
                            ctaLink2: "https://tne.reseau-canope.fr/outils-numeriques/solutions-numeriques-educatives/solution/tactimalin-primaire.html",
                            cta1: "<span class='mobile-hide'>Primaire</span> Fondamentaux",
                            ctaLink1: "https://tne.reseau-canope.fr/outils-numeriques/solutions-numeriques-educatives/pack/tactimalin-primaire-fondamentaux.html",
                        },
                        {
                            picture: "/img/tne/tactimalin-collège.png",
                            title: "Tactimalin Collège",
                            titleStyle: "center",
                            alignItems: "center",
                            text: "Tactimalin Collège propose des centaines de ressources pour consolider les fondamentaux de l’école primaire, découvrir et s’approprier les notions du collège, réviser et s’entraîner efficacement pour le Brevet des collèges.",
                            legends: "<span>Tactimalin Collège Fondamentaux<quote><span>Mathématiques, Français, Enseignement moral et civique et Histoire géographie.</span></quote></span>",
                            legendsPosition: "centered",
                            plus: false,
                            columnCount: 1,
                            cta: "Collège Fondamentaux",
                            ctaLink: "https://tne.reseau-canope.fr/outils-numeriques/solutions-numeriques-educatives/pack/tactimalin-college-fondamentaux.html",
                        },
                        {
                            picture: "/img/tne/tactimalin-lycée.png",
                            title: "Tactimalin Lycée",
                            titleStyle: "center",
                            alignItems: "center",
                            text: "Tactimalin Lycée propose des centaines de ressources pour consolider les fondamentaux du collège, découvrir et s’approprier les notions du lycée, développer des compétences transversales, réviser et s’entraîner efficacement pour le baccalauréat général, technologique ou professionnel et les épreuves de CAP.",
                            legends: "<span>Tactimalin Lycée Fondamentaux<quote><span>Mathématiques, Français, Enseignement moral et civique, Histoire géographie (lycée professionnel), préparation au grand oral et oral du chef d’oeuvre.</span></quote></span><span>Tactimalin Lycée<quote><span>Langues vivantes, Humanités numériques, Enseignements artistiques, Sciences et technologie, Education au développement durable, Histoire géographie et Prévention-santé-environnement (lycée professionnel).</span></quote></span>",
                            plus: false,
                            columnCount: 1,
                            cta2: "Lycée",
                            ctaLink2: "https://tne.reseau-canope.fr/outils-numeriques/solutions-numeriques-educatives/solution/tactimalin-lycee.html",
                            cta1: "<span class='mobile-hide'>Lycée</span> Fondamentaux",
                            ctaLink1: "https://tne.reseau-canope.fr/outils-numeriques/solutions-numeriques-educatives/solution/tactimalin-lycee-fondamentaux.html",
                        },
                        {
                            picture: "/img/tne/tactimalin-inclusion.png",
                            title: "Tactimalin Inclusion",
                            titleStyle: "center",
                            alignItems: "center",
                            text: "Tactimalin Ecole Inclusive propose des centaines de ressources pour tous les élèves particulièrement adaptées à l’inclusion des élèves allophones, en dispositif ULIS, en classe SEGPA ou en CAP.",
                            legends: "<span>Tactimalin Inclusion<quote><span>Mathématiques, Français  en SEGPA; Mathématiques, Français, Histoire géographie, Prévention-santé-environnement en CAP et Français Langue Étrangère pour les élèves du cycle 2 au cycle terminal.</span></quote></span>",
                            legendsPosition: "centered",
                            plus: false,
                            columnCount: 1,
                            cta: "Inclusion",
                            ctaLink: "https://tne.reseau-canope.fr/outils-numeriques/solutions-numeriques-educatives/solution/tactimalin-ecole-inclusive.html",
                        }
                    ]
                },
                {
                    _component: EduPartners,
                    _name: "partenaires2",
                    marginTopPartners: "margin-top: 20px",
                    title: "Nos partenaires",
                    partners: [
                        {
                            picture: "/img/tne/logo-maskott-tactileo.png",
                            alternativeText: "Logo Maskott Tactileo"
                        },
                        {
                            picture: "/img/tne/logo-learn-enjoy.png",
                            alternativeText: "Logo Learn Enjoy"
                        },
                        {
                            picture: "/img/tne/logo-hatier.png",
                            alternativeText: "Logo Hatier"
                        },
                        {
                            picture: "/img/tne/logo-vittascience.png",
                            alternativeText: "Logo Vitta Science"
                        },
                        {
                            picture: "/img/tne/logo-cabrilog.png",
                            alternativeText: "Logo Cabrilog"
                        },
                        {
                            picture: "/img/tne/logo-lesbonsprofs.svg",
                            alternativeText: "Logo Les Bons Profs"
                        }
                    ]
                },
                {
                    _component: EduOnlyTitle,
                    _name: "savoir_plus",
                    title: "Pour en savoir plus",
                    text: "Rendez-vous sur <a href='https://www.tactimalin.education' target='_blank' rel='noreferrer' class='open-in-new-tab'>https://www.tactimalin.education</a>"
                },
            ]
        },
        faq: {
            _path: "/faq",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduFaqs,
                    _name: "techfaq",
                    title: "FAQ Technique",
                    faqs: "technicalFaqs",
                    rowLayout: [[1, 2], [1, 1, 1], [2, 1]]
                },
                {
                    _component: EduFaqs,
                    _name: "pedafaq",
                    title: "FAQ Pédagogique",
                    faqs: "pedagogicalFaqs",
                    rowLayout: [[3], [1, 2], [2, 1], [1, 2]]
                },
            ]
        },
        support: {
            _path: "/support",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduContact,
                    _name: "support",
                    title: "Contacter le support",
                    hilight: "Dites-nous tout !",
                    text: "Quel que soit votre sujet, n’hésitez pas à nous envoyer un message. Nous y répondrons dans les meilleurs délais.",
                    picture: "/img/illus-support.svg",
                    cta: "Envoyer",
                    subjects: [
                        { label: "Ecole primaire", value: "ecole-primaire" },
                        { label: "Collège", value: "college" },
                        { label: "Lycée", value: "lycee" },
                        { label: "Lycée Professionnel", value: "lycee-pro" },
                        { label: "CFA", value: "cfa" },
                        { label: "Collectivité", value: "collectivite" },
                        { label: "Parents", value: "parents" }
                    ],
                    thankyou: {
                        title: "Merci !",
                        text: "Nous allons vous répondre dans <b>les meilleurs délais</b>."
                    }
                },
            ]
        },

        science,

        falcScolaire,
        usage_cas_inclusion: {
            _path: "/cas-usages-inclusion",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduUseCasesFalc,
                    _name: "cases",
                    title: " Cas d'usages Inclusion",
                }
            ]
        },

        equipe: {
            _path: "/equipe",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduTeam,
                    _name: "equipe",
                    title: "Notre équipe",
                },
                {
                    _component: EduDates,
                    _name: "equipe",
                    title: "Edumalin en 4 dates",
                    dates: [
                        {
                            color: "red",
                            date: "2016",
                            title: "Les origines",
                            text: "<b>15 ans d’expérience dans l’enseignement</b> et un constat partagé : de nombreux " +
                                "élèves sont en échec parce qu’ils n’ont pas <b>les outils et méthodes pour apprendre</b>."
                        },
                        {
                            color: "blue",
                            date: "2016-2018",
                            title: "La preuve de concept",
                            text: "Très rapidement épaulés et <b>soutenus par des collèges et l’Académie de Paris</b>, nous construisons, testons et validons nos <b>méthodes pédagogiques</b>."
                        },
                        {
                            color: "darkblue",
                            date: "2018-2020",
                            title: "La consolidation",
                            text: "<b>Intérêt pédagogique avéré</b>, hausse considérable des usages et de la satisfaction des utilisateurs. <b>Le projet se consolide</b> et change de dimension."
                        },
                        {
                            color: "green",
                            date: "2021 et +",
                            title: "Une identité forte",
                            text: "<b>200k+ utilisateurs dès septembre 2021</b>, de nombreux projets et collaborations en cours. " +
                                "Notre démarche semble identifiée et <b>reconnue par de nombreux acteurs</b>."
                        }
                    ]
                }
            ]
        },
        principes: {
            _path: "/les-7-principes",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduPoints,
                    _name: "support",
                    title: "Les 7 principes Edumalin",
                    subtitle: "Retrouvez ci-dessous <b>les 7 principes qui guident nos pas au quotidien</b> au carrefour de la pédagogie, des sciences de l’apprentissage et du numérique.",
                    points: [
                        {
                            icon: "/img/principes/principe-start.svg",
                            color: "blue",
                            text: "Edumalin se situe <b>au croisement de l'expertise d'enseignants, des sciences de l'apprentissage et des résultats de la recherche</b>." +
                                "Articuler constamment ces données issues de la recherche et les problématiques rencontrées au quotidien par les enseignants est l'un de nos principaux " +
                                "« fil d'Ariane ».\n" +
                                "<b>C'est ainsi que nous concevons notre démarche fondée sur les preuves</b>."
                        },
                        {
                            icon: "/img/principes/principe-1.svg",
                            text: "<b>Principe n°1 :</b>\nla réussite est un moteur essentiel de la motivation et de son maintien dans le temps (S.Masson le souligne + expériences de DePasque et al., 2014 ; Garon-Carrier et al., 2016 ; Wilkinson et al., 2014)"
                        },
                        {
                            icon: "/img/principes/principe-2.svg",
                            text: "<b>Principe n°2 :</b>\nmettre en place un cercle vertueux entre efforts fournis, réussite, bénéfices perçus par l'apprenant et le désir/envie d'apprendre. " +
                                "Développer explicitement et dans le coeur des disciplines les compétences d'autorégulation des apprentissages."
                        },
                        {
                            icon: "/img/principes/principe-3.svg",
                            text: "<b>Principe n°3 :</b>\nintégrer des méthodes d'apprentissage dont l'efficacité est éprouvée scientifiquement (effet de tests, d'espacement, d'alternance) " +
                                "et ainsi faciliter la mise en place d'évaluation formative (ancrage durable des connaissances)"
                        },
                        {
                            icon: "/img/principes/principe-4.svg",
                            text: "<b>Principe n°4 :</b>\nfournir une visualisation des données d'apprentissage en temps réel à l'échelle macro et micro. " +
                                "Utile pour l'élève qui est témoin de sa progression et l'enseignant pour l'aider à mieux détecter la zone proximale de développement. " +
                                "La différenciation et les retours correctifs sont ainsi plus précis."
                        },
                        {
                            icon: "/img/principes/principe-5.svg",
                            text: "<b>Principe n°5 :</b>\nlaisser le choix aux élèves de sélectionner des exercices en particulier, une marge de liberté étant favorable au maintien de l'engagement."
                        },
                        {
                            icon: "/img/principes/principe-6.svg",
                            text: "<b>Principe n°6 :</b>\nla plateforme apporte une dimension plus ludique à l'apprentissage avec un design visuel attrayant mais " +
                                "ne sature pas la charge cognitive ou l'attention des élèves."
                        },
                        {
                            icon: "/img/principes/principe-7.svg",
                            text: "<b>Principe n°7 :</b>\nla plateforme est avant tout un outil pédagogique au service de l'enseignant. " +
                                "Sa structuration va l'aider à mieux expliciter les buts d'apprentissage et scénariser les activités du travail personnel."
                        },
                    ]
                }
            ]
        },
        manifeste,
        actus: {
            _path: "/actualites",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduEvents,
                    _name: "future",
                    title: "Les prochains webinaires Edumalin",
                    subtitle: "Formations, webconférences, évènements : <b>retrouvez-nous et échangeons</b>.",
                    events: "webinaires",
                    old: false
                },
                {
                    _component: EduOnlyTitle,
                    _name: "support",
                    title: "Nos actualités",
                    subtitle: "En direct de notre compte twitter <a href='https://twitter.com/Edumalin_' target='_blank'>@Edumalin_</a>"
                },
                {
                    _component: EduTweets,
                    _name: "tweets",
                    twitterId: "Edumalin_"
                },
                {
                    _component: EduEvents,
                    _name: "old",
                    title: "Les webinaires Edumalin à revoir",
                    events: "webinaires",
                    old: true
                },
            ]
        },
        temoignages: {
            _path: "/temoignages",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduTestimonies,
                    _name: "all_testimonies",
                    title: "Les témoignages de nos utilisateurs"
                }
            ]
        },

        contact: {
            _path: "/contact",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduContact,
                    _name: "contact",
                    title: "Contactez-nous",
                    hilight: "Dites-nous tout !",
                    text: "Quel que soit votre sujet, n’hésitez pas à nous envoyer un message. Nous y répondrons dans les meilleurs délais.",
                    picture: "/img/illus-contact.svg",
                    cta: "Envoyer",
                    thankyou: {
                        title: "Merci !",
                        text: "Nous allons vous répondre dans <b>les meilleurs délais</b>."
                    }
                },
            ]
        },
        demo: {
            _path: "/demo",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduContact,
                    _name: "demo",
                    title: "Demandez une démo",
                    hilight: "Vous souhaitez voir la plateforme en action ?",
                    text: "Remplissez ce formulaire en nous indiquant dans le message <b>vos disponibilités</b> et nous vous recontacterons au plus vite afin de <b>convenir d'un rendez-vous</b> ou d'une visio pour vous faire une démonstration de notre outil.",
                    picture: "/img/illus-demo.svg",
                    cta: "Envoyer",
                    subjects: [
                        { label: "Ecole primaire", value: "ecole-primaire" },
                        { label: "Collège", value: "college" },
                        { label: "Lycée", value: "lycee" },
                        { label: "Lycée Professionnel", value: "lycee-pro" },
                        { label: "CFA", value: "cfa" },
                        { label: "Collectivité", value: "collectivite" },
                        { label: "Parents", value: "parents" }
                    ],
                    thankyou: {
                        title: "Merci !",
                        text: "Nous allons vous recontacter dans <b>les meilleurs délais</b>."
                    }
                },
            ]
        },

        mentionslegales: {
            _path: "/mentions-legales",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduText,
                    _name: "mentions_legales",
                    title: "Mentions Légales",
                    text: "Le site Edumalin.fr est édité par la SAS MFB\n" +
                        "<b>Informations société</b> : SAS au capital de 25 510€, immatriculée au registre du Commerce et des sociétés de Paris sous le numéro 821 637 816, dont le siège social est situé à Paris 06.\n" +
                        "<b>Directeur de la publication</b> : François Baritiu en sa qualité de président de la SAS MFB.\n" +
                        "<b>Contact</b> : contact@edumalin.fr\n" +
                        "<b>Hébergement</b> : OVH SAS au capital de 10000000 d'euros, dont le siège social est situé 2, rue Kellermann, 59100 ROUBAIX-FRANCE , RCS Roubaix-Tourcoing, numéro SIREN 424276141900045 représentée par Henryk KABLA, en sa qualité de Président, Adresse de courrier électronique : support@ovh.com\n" +
                        "<b>Données personnelles</b> : Conformément à la loi n° 78- 17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés (« Loi Informatique et Libertés »), le Site a fait l'objet d'une déclaration auprès de la Commission nationale de l'informatique et des libertés (« CNIL ») sous le numéro 1984312 v 0.\n" +
                        "Conformément à l'article 34 de la loi « Informatique et Libertés », la société garantit à l'utilisateur la possibilité, pour des motifs légitimes, d'exercer son droit d'opposition, d'accès, de mise à jour, de suppression et de rectification sur les données le concernant.\n" +
                        "L'utilisateur a la possibilité d'exercer ce droit en envoyant sa demande par email à l'adresse suivante : contact@edumalin.fr\n" +
                        "La charte de déontologie et les conditions générales d'utilisation définissent les règles d'utilisation du site.\n" +
                        "<b>Propriété intellectuelle</b> : la marque et le logo EDUMALIN sont déposés à l'INPI et sont la propriété intellectuelle de la SAS MFB.\n" +
                        "<b>Financement du site</b> : le site est exclusivement financé par des fonds privés."
                }
            ]
        },
        rgpd: {
            _path: "/rgpd",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduRgpd,
                    _name: "rgpd",
                    title: "Données personnelles : Edumalin, un partenaire de confiance"
                }
            ]
        },
        cgps: {
            _path: "/conditions-generales-de-prestations-de-service",
            _layout: "main",
            _pageLayout: "horizontalLayers",
            layers: [
                {
                    _component: EduCgps,
                    _name: "cgps-content",
                    firstTitle: "CONDITIONS GENERALES DE PRESTATIONS DE SERVICE EDUMALIN",
                    sections: [
                        {
                            level: 1,
                            title: "ARTICLE 1 - Champ d'application",
                            blocks: [
                                "Les présentes Conditions Générales de Vente constituent, conformément à l'article L 441-6 du Code du Commerce, le socle unique de la relation commerciale entre les parties.",
                                "Elles ont pour objet de définir les conditions dans lesquelles la société M.F.B, SAS au capital de 25.510 EUROS, immatriculée au RCS de PARIS sous le numéro 821 637 816, dont le siège social est situé 77 rue Balard, 75015 PARIS, représentée par son Président, Monsieur François BARITIU (« Le Prestataire ») fournit aux Clients professionnels (« Les Clients ou le Client ») qui lui en font la demande, les services suivants :",
                                "<ol><li>Accès à une plateforme Web de type «etablissement.edumalin.fr » comprenant :" +
                                "<ol><li>Une page d’accueil,</li>" +
                                "<li>La possibilité de se connecter en tant qu’élève (cellule familiale) ou professeur.</li>" +
                                "<li>L’accès à des ressources pédagogiques</li>" +
                                "<li>Un suivi du travail des élèves</li></ol></li>" +
                                "<li>Selon le choix retenu par le chef d’établissement : une présentation et explication de la plateforme aux enseignants et/ou familles.</li></ol>",
                                "Ci-après « Les Services ».",
                                "Elles s'appliquent, sans restriction ni réserve, à tous les Services rendus par le Prestataire auprès des Clients de même catégorie, quelles que soit les clauses pouvant figurer sur les documents du Client.",
                                "Conformément à la réglementation en vigueur, ces Conditions Générales de Vente sont systématiquement communiquées à tout Client qui en fait la demande, pour lui permettre de passer commande auprès du Prestataire.",
                                "Toute commande de Services implique, de la part du Client, l'acceptation des présentes Conditions Générales de Vente et des conditions générales d'utilisation du site internet du Prestataire."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 2 - Commandes",
                            subLevel: 4,
                            subtitle: "2-1",
                            blocks: [
                                "Les ventes de Services ne sont parfaites qu'après acceptation expresse et par écrit du devis établi par le Prestataire par le Client, matérialisée par sa signature."
                            ],
                        },
                        {
                            subLevel: 4,
                            subtitle: "2-2",
                            blocks: [
                                "Les éventuelles modifications de la commande demandées par le Client ne seront prises en compte, dans la limite des possibilités du Prestataire, que si elles sont notifiées par écrit, 30 jours au moins avant la date prévue pour la fourniture de Services commandés, après signature par le Client d'un bon de commande spécifique et ajustement éventuel du prix."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 3 - Tarifs",
                            blocks: [
                                "Les prestations de services sont fournies aux tarifs du Prestataire en vigueur au jour de la passation de la commande, selon, comme indiqué à l'article “ Commandes ” ci-dessus.",
                                "Les tarifs s'entendent nets et HT.",
                                "Une facture est établie par le Prestataire et remise au Client lors de chaque fourniture de Services.",
                                "Les conditions de détermination du coût des services dont le prix ne peut être connu a priori ni indiqué avec exactitude, ainsi que la méthode de calcul du prix permettant de vérifier ce dernier, seront communiquées au Client ou feront l'objet d'un devis détaillé, à la demande du Client conformément aux dispositions de l'article L 441-6,II du Code de commerce."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 4 - Conditions de règlement",
                            subLevel: 4,
                            subtitle: "4-1 . Délais de règlement",
                            blocks: [
                                "Dès réception de la facture."
                            ]
                        },
                        {
                            subLevel: 4,
                            subtitle: "4-2 . Pénalités de retard",
                            blocks: [
                                "En cas de retard de paiement et de versement des sommes dues par le Client au-delà du délai ci-dessus fixé, et après la date de paiement figurant sur la facture adressée à celui-ci, des pénalités de retard calculées au taux légal en vigueur du montant TTC du prix des Services figurant sur ladite facture, seront automatiquement et de plein droit acquises au Prestataire, sans formalité aucune ni mise en demeure préalable."
                            ]
                        },
                        {
                            subLevel: 4,
                            subtitle: "4-3 . Absence de compensation",
                            blocks: [
                                "Sauf accord exprès, préalable et écrit du Prestataire, et à condition que les créances et dettes réciproques soient certaines, liquides et exigibles, aucune compensation ne pourra être valablement effectuée par le Client entre d'éventuelles pénalités pour retard dans la fourniture des Services commandés ou non-conformité à la commande, d'une part, et les sommes par le Client au Prestataire au titre de l'achat desdits Services, d'autre part."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 5 - Modalités de fourniture des Services",
                            blocks: [
                                "Les Services demandés par le Client seront fournis dans les délais indiqués dans le devis et bon de commande correspondant dûment signés, accompagnés de l'acompte éventuellement exigible.",
                                "Ces délais ne constituent pas un délai de rigueur et le Prestataire ne pourra voir sa responsabilité engagée à l'égard du Client en cas de retard dans la fourniture des Services n'excédant pas 10 jours. En cas de retard supérieur à 2 mois, le Client pourra demander la résolution de la vente. Les acomptes déjà versés lui seront alors restitués par le Prestataire.",
                                "La responsabilité du Prestataire ne pourra en aucun cas être engagée en cas de retard ou de suspension de la fourniture de la prestation imputable au Client, ou en cas de force majeure ",
                                "Les Services seront fournis à l’adresse du Client.",
                                "A défaut de réserves ou réclamations expressément émises par le Client lors de la réception des Services, ceux-ci seront réputés conformes à la commande, en quantité et qualité. ",
                                "En cas de demande particulière du Client concernant les conditions de fourniture des Services, dûment acceptées par écrit par le Prestataire, les coûts y liés feront l'objet d'une facturation spécifique complémentaire, sur devis préalablement accepté par le Client."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 6 – Nature des relations et Responsabilités",
                            blocks: [
                                "Le Prestataire exécute la prestation commandée uniquement en qualité de conseiller indépendant sans lien de subordination avec le Client.",
                                "Le Prestataire fera des recommandations fondées sur son expérience, mais le client et chacun de ses subordonnés conserveront toujours la responsabilité de toutes leurs décisions, lesquelles seront prises à leur seule discrétion."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 7 – Données personnelles",
                            blocks: [
                                "Edumalin s’inscrit dans une démarche continue de conformité avec le RGPD (Règlement général sur la protection des données).",
                                "Les données de ses utilisateurs sont collectées et utilisées de manière transparente, confidentielle et sécurisée.",
                                "En application de la <a href='http://abonnes.efl.fr/EFL2/convert/id/?id=RFFD69931D1FI980A-EFL' target='_blank' rel='noreferrer' class='open-in-new-tab'>loi 78-17 du 6 janvier 1978</a>, il est rappelé que les données nominatives qui sont demandés au Client sont nécessaires au traitement de sa commande et à l'établissement des factures, notamment.<br>Ces données peuvent être communiquées aux éventuels partenaires du Vendeur chargés de l'exécution, du traitement, de la gestion et du paiement des commandes.<br>Le traitement des informations communiquées par l'intermédiaire du site internet www.edumalin.fr répond aux exigences légales en matière de protection des données personnelles, le système d'information utilisé assurant une protection optimale de ces données.<br>Le Client dispose, conformément aux réglementations nationales et européennes en vigueur d'un droit d'accès permanent, de modification, de rectification, d'opposition de portabilité et de limitation du traitement s'agissant des informations le concernant.<br>Ce droit peut être exercé dans les conditions et selon les modalités définies sur le site internet www.edumalin.fr.",
                                "En application des dispositions de la loi informatique et liberté :",
                                "<ul><li>Le Prestataire s’engage à n’utiliser les données personnelles collectées sur la plateforme que pour les besoins des Services, et s’interdit de publier, divulguer ou transmettre des informations concernant les utilisateurs sans leur accord préalable,</li>" +
                                "<li>Chaque utilisateur de la plateforme dispose d’un droit d’accès et de rectification de données personnelles le concernant exerçable auprès du Prestataire, à l’adresse de son siège social.</li></ul>"
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 8 – Confidentialité",
                            blocks: [
                                "Le Prestataire devra maintenir la confidentialité des informations échangées avec les utilisateurs de la plateforme et ne les communiquer à aucune personne ou entité non liée par les présentes. Le Prestataire n’utilisera pas l’information confidentielle pour d’autres usages que ceux prévus pour l’exécution des Services sans l’accord écrit préalable du Client."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 9 – Publicité",
                            blocks: [
                                "Le Client accepte de figurer sur la liste des références clients du Prestataire à titre de référence commerciale."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 10 - Responsabilité du Prestataire - Garantie",
                            blocks: [
                                "Le Prestataire garantit, conformément aux dispositions légales, le Client, contre tout défaut de conformité des Services et tout vice caché, provenant d'un défaut de conception ou de fourniture desdits Services à l'exclusion de toute négligence ou faute du Client.",
                                "La responsabilité du Prestataire ne peut être engagée qu'en cas de faute ou de négligence prouvée et est limitée aux préjudices directs à l'exclusion de tout préjudice indirect, de quelque nature que ce soit.",
                                "Afin de faire valoir ses droits, le Client devra, sous peine de déchéance de toute action s'y rapportant, informer le Prestataire, par écrit, de l'existence des vices dans un délai maximum de 5 jours ouvrables à compter de leur découverte.",
                                "Le Prestataire rectifiera ou fera rectifier, à ses frais exclusifs, selon les modalités adéquates et agréées par le Client, les Services jugés défectueux.",
                                "En tout état de cause, au cas où la responsabilité du Prestataire serait retenue, la garantie du Prestataire serait limitée au montant HT payé par le Client pour la fourniture des Services.",
                                "La garantie du Prestataire est limitée au remboursement des Services effectivement payés par le Client et le Prestataire ne pourra être considéré comme responsable ni défaillant pour tout retard ou inexécution consécutif à la survenance d'un cas de force majeure habituellement reconnu par la jurisprudence française."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 11 - Droit de propriété intellectuelle",
                            blocks: [
                                "Tout le contenu des sites web et programmes informatiques, logiciels, produits, éléments graphiques d’interface, études, modèles, ressources pédagogiques, outils, etc, réalisés (même à la demande du Client) en vue de la fourniture des services au Client, ou des autres éléments associés aux Services fournis par le Prestataire est protégé par des droits de propriété intellectuelle appartenant exclusivement au Prestataire.",
                                "Le Client s'interdit donc toute reproduction ou exploitation desdites études, modèles, ressources pédagogiques, outils, etc, sans l'autorisation expresse, écrite et préalable du Prestataire qui peut la conditionner à une contrepartie financière.",
                                "Le Client n’est pas autorisé à copier, modifier, distribuer, publier, transmettre ou créer des travaux dérivés de tout élément de ce contenu.",
                                "L’accès à la plateforme est concédé en licence gratuite. Les licences accordées ne donnent aucun droit sur le contenu du site web programmes informatiques, logiciels, logos, icônes et marques identifiant les produits et services du Prestataire qui ne doivent pas être utilisés sans la permission expresse et par écrit du Prestataire.",
                                "Les droits sur les contenus, méthodes et modules créés par les utilisateurs à partir des ressources et services associés à la plateforme numérique appartiennent aux utilisateurs."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 12 – Durée de la prestation",
                            blocks: [
                                "Les services sont fournis pour une durée d’une année, se terminant le 15/08",
                                "Les services prennent effet à la date de signature du devis ou au plus tard, le 1er septembre de l'année au cours duquel le devis est signé.",
                                "Aux termes de l'article L 215 -1 du Code de la consommation, littéralement reproduit :" +
                                '<br>"Pour les contrats de prestations de services conclus pour une durée déterminée avec une clause de reconduction tacite, le professionnel prestataire de services informe le consommateur par écrit, par lettre nominative ou courrier électronique dédiés, au plus tôt trois mois et au plus tard un mois avant le terme de la période autorisant le rejet de la reconduction, de la possibilité de ne pas reconduire le contrat qu\'il a conclu avec une clause de reconduction tacite. Cette information, délivrée dans des termes clairs et compréhensibles, mentionne, dans un encadré apparent, la date limite de non-reconduction.' +
                                "<br>Lorsque cette information ne lui a pas été adressée conformément aux dispositions du premier alinéa, le consommateur peut mettre gratuitement un terme au contrat, à tout moment à compter de la date de reconduction." +
                                '<br>Les avances effectuées après la dernière date de reconduction ou, s\'agissant des contrats à durée indéterminée, après la date de transformation du contrat initial à durée déterminée, sont dans ce cas remboursées dans un délai de trente jours à compter de la date de résiliation, déduction faite des sommes correspondant, jusqu\'à celle-ci, à l\'exécution du contrat. Les dispositions du présent article s\'appliquent sans préjudice de celles qui soumettent légalement certains contrats à des règles particulières en ce qui concerne l\'information du consommateur."',
                                "Les Services proposés par le Prestataire sont fournis aux tarifs en vigueur selon le devis établi par le prestataire, lors de l'enregistrement de la commande par le Prestataire. Les prix sont exprimés en Euros, HT et TTC." +
                                "<br>Les tarifs tiennent compte d'éventuelles réductions qui seraient consenties par le Prestataire dans les conditions précisées sur le devis." +
                                "<br>Ces tarifs sont fermes et non révisables pendant leur période de validité, telle qu'indiqué sur le devis, le Prestataire se réservant le droit, hors cette période de validité, de modifier les prix à tout moment."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 13 - Exécution forcée en nature",
                            blocks: [
                                "La Partie victime de la défaillance pourra, en cas d'inexécution de l'une quelconque des obligations incombant à l'autre Partie, demander la résolution du contrat selon les modalités définies à l'article « Résolution du contrat »."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 14 - Exception d'inexécution",
                            blocks: [
                                "Il est rappelé qu'en application de l'article 1219 du Code civil, chaque Partie pourra refuser d'exécuter son obligation, alors même que celle-ci est exigible, si l'autre Partie n'exécute pas la sienne et si cette inexécution est suffisamment grave, c'est-à-dire, susceptible de remettre en cause la poursuite du contrat ou de bouleverser fondamentalement son équilibre économique. La suspension d'exécution prendra effet immédiatement, à réception par la Partie défaillante de la notification de manquement qui lui aura été adressée à cet effet par la Partie victime de la défaillance indiquant l'intention de faire application de l'exception d'inexécution tant que la Partie défaillante n'aura pas remédié au manquement constaté, signifiée par lettre recommandée avec demande d'avis de réception ou sur tout autre support durable écrit permettant de ménager une preuve de l'envoi.",
                                "Cette exception d'inexécution pourra également être utilisée à titre préventif, conformément aux dispositions de l'article 1220 du Code civil, s'il est manifeste que l'une des Parties n'exécutera pas à l'échéance les obligations qui lui incombent et que les conséquences de cette inexécution sont suffisamment graves pour la Partie victime de la défaillance.",
                                "Cette faculté est utilisée aux risques et périls de la Partie qui en prend l'initiative.",
                                "La suspension d'exécution prendra effet immédiatement, à réception par la Partie présumée défaillante de la notification de l'intention de faire application de l'exception d'inexécution préventive jusqu'à ce que la Partie présumée défaillante exécute l'obligation pour laquelle un manquement à venir est manifeste, signifiée par lettre recommandée avec demande d'avis de réception ou sur tout autre support durable écrit permettant de ménager une preuve de l'envoi.",
                                "Si l'empêchement était définitif ou perdurait au-delà 2 mois, les présentes seraient purement et simplement résolues selon les modalités définies à l'article Résolution pour manquement d'une partie à ses obligations."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 15 - Force majeure",
                            blocks: [
                                "Les Parties ne pourront être tenus pour responsables si la non-exécution ou le retard dans l'exécution de l'une quelconque de leurs obligations, telles que décrites dans les présentes découle d'un cas de force majeure, au sens de l'article 1218 du Code civil.",
                                "La partie constatant l'événement devra sans délai informer l'autre partie de son impossibilité à exécuter sa prestation et s'en justifier auprès de celle-ci. La suspension des obligations ne pourra en aucun cas être une cause de responsabilité pour non-exécution de l'obligation en cause, ni induire le versement de dommages et intérêts ou pénalités de retard.",
                                "L'exécution de l'obligation est suspendue pendant toute la durée de la force majeure si elle est temporaire. Par conséquent, dès la disparition de la cause de la suspension de leurs obligations réciproques, les parties feront tous leurs efforts pour reprendre le plus rapidement possible l'exécution normale de leurs obligations contractuelles. A cet effet, la partie empêchée avertira l'autre de la reprise de son obligation par lettre recommandée avec demande d'avis de réception ou tout acte extrajudiciaire. Si l'empêchement est définitif, les présentes seront purement et simplement résolues selon les modalités définies à l'article « Résolution pour force majeure »."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 16 - Résolution du contrat",
                            subLevel: 4,
                            subtitle: "16-1 - Résolution pour manquement d'une partie à ses obligations",
                            blocks: [
                                "En cas de non-respect par l'une ou l'autre des parties des obligations visées aux articles du présent contrat, celui-ci pourra être résolu au gré de la partie lésée."
                            ]
                        },
                        {
                            subLevel: 4,
                            subtitle: "16-2 - Dispositions communes aux cas de résolution",
                            blocks: [
                                "En tout état de cause, la Partie lésée pourra demander en justice l'octroi de dommages et intérêts."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 17 – Litiges",
                            blocks: [
                                "Tous les litiges auxquels le présent contrat pourrait donner lieu, concernant tant sa validité, son interprétation, son exécution, sa résolution, leurs conséquences et leurs suites seront soumis aux tribunaux compétents dans les conditions de droit commun."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 18 - Langue du contrat - Droit applicable",
                            blocks: [
                                "De convention expresse entre les parties, les présentes Conditions Générales de Vente et les opérations d'achat et de vente qui en découlent sont régies par le droit français.",
                                "Elles sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues, seul le texte français ferait foi en cas de litige."
                            ]
                        },
                        {
                            level: 1,
                            title: "ARTICLE 19 - Acceptation du Client",
                            blocks: [
                                "Les présentes Conditions Générales de Vente sont expressément agréés et acceptés par le Client, qui déclare et reconnaît en avoir une parfaite connaissance, et renonce, de ce fait, à se prévaloir de tout document contradictoire qui sera inopposable au Prestataire, même s'il en a eu connaissance."
                            ]
                        },
                    ]
                }
            ]
        }
    },
    data: {
        _global: {},
        versions: [
            {
                name: "Primaire",
                level: "Niveaux CE2, CM1, CM2",
                texts: [
                    "<b>Maths, Français et EMC</b> sous forme de parcours pour <b>les niveaux CE2, CM1, CM2</b>.",
                    "Pour que cette <b>transition fondamentale</b> entre primaire et collège soit <b>plus sereine</b> pour les élèves et leurs familles.",
                    "Options : Anglais, Humanités Numériques, Éducation au Développement Durable, Enseignements artistiques et Sciences et Technologie."
                ],
                cta: "En savoir plus"
            },
            {
                name: "Collège",
                level: "Niveau collège",
                texts: [
                    "<b>Maths, Français, EMC, Histoire-Géographie et Prépa Brevet</b> sous forme de parcours pour <b>tous les niveaux</b> (6e, 5e, 4e et 3e).",
                    "Devoir faits, enseignement à distance, AP. Une version qui a déjà fait toutes ses preuves.",
                    "Option : Anglais"
                ],
                cta: "En savoir plus"
            },
            {
                name: "SEGPA",
                level: "Niveaux collège et SEGPA",
                texts: [
                    "<b>Maths et Français</b> sous forme de parcours pour <b>tous les niveaux</b> (6e, 5e, 4e, 3e SEGPA) et Prépa Brevet.",
                    "Une approche sécurisante et <b>adaptée</b> pour des élèves à besoins éducatifs particuliers auxquels nos <b>principes de guidage</b> conviennent particulièrement.",
                    "Option : Anglais"
                ],
                cta: "En savoir plus"
            },
            {
                name: "LP",
                level: "Niveaux CAP et Bac Pro",
                texts: [
                    "<b>Maths/Sciences, Français/HG et PSE</b> pour les niveaux <b>CAP et Bac Pro</b>.",
                    "Un outil <b>simple et très efficace</b> pour faire face aux <b>difficultés</b> (notamment en Maths et Français*) que rencontrent <b>60% des élèves</b> à leur entrée en filière professionnelle.",
                    "Options : Préparation Oral du Chef d'œuvre, Anglais, Humanités Numériques, Éducation au Développement Durable, Enseignements Artistiques",
                    "<i>* Source DEPP : résultats test de positionnement en 2<sup>nde</sup></i>"
                ],
                cta: "En savoir plus"
            },
            {
                name: "LGT",
                level: "Niveaux 2nde, 1ère et Terminale GT",
                texts: [
                    "<b>Maths et Français</b> sous forme de parcours pour <b>tous les niveaux du LGT et Prépa Bac</b>.",
                    "Pour accompagner les élèves de manière progressive vers les exigences et la complexité croissante des réflexions en Maths et Français et gommer le plus possible la marche entre collège et lycée.",
                    "Options : Préparation au Grand oral, EMC, Anglais, Humanités Numériques, Éducation au Développement Durable, Enseignements Artistiques"
                ],
                cta: "En savoir plus"
            },
            {
                name: "Apprenti",
                level: "Niveaux CAP et Bac Pro",
                texts: [
                    "<b>Maths/Sciences, Français/HG et PSE</b> pour les niveaux <b>CAP et Bac Pro</b>.",
                    "<b>Un outil déterminant</b> pour aider les <b>jeunes en apprentissage</b> qui éprouvent parfois des difficultés dans l’approche des <b>matières du tronc commun</b> de CAP et de BacPro.",
                    "<b>Se rassurer sur ses capacités à apprendre</b> est un <b>enjeu considérable</b> pour ces jeunes.\n",
                    "Options : Préparation Oral du Chef d'œuvre, Anglais, Humanités Numériques, Éducation au Développement Durable, Enseignements Artistiques"
                ],
                cta: "En savoir plus"
            },
        ],
        partners: [
            { picture: "/img/partners/edu-up.png" },
            { picture: "/img/partners/bpi.png" },
            { picture: "/img/partners/region-idf.png" },
            { picture: "/img/partners/region-na.png" },
            { picture: "/img/partners/region-normandie.png" },
            { picture: "/img/partners/centre-val-de-loire.png" },
            { picture: "/img/partners/calvados.png" },
            { picture: "/img/partners/acad-paris.png" },
            { picture: "/img/partners/martinique.png" },
            { picture: "/img/partners/ministere-educ-nat-jeunesse.png" },
            { picture: "/img/partners/rouge-bleu.jpeg" },
            { picture: "/img/partners/banque-des-territoires.png" },
            { picture: "/img/partners/canope.png" },
            { picture: "/img/partners/trousse-a-projet.png" },
        ],
        testimonies: [
            {
                text: "3' pour découvrir les usages possibles d'Edumalin en collège dans le cadre de Devoirs Faits.",
                video: "https://www.youtube.com/embed/Pm3WMVihSAk",
                picture: "/testimonies/thumbnail01.jpg"
            },
            {
                text: "\"Simplicité d'utilisation\", \"engagement des élèves\", découvrez les usages et les effets en Accompagnement Personnalisé en LP.",
                video: "https://www.youtube.com/embed/HfNstdT7z7s",
                picture: "/testimonies/thumbnail02.jpg"
            },
            {
                text: "La PSE (matière du LP) est mise à l'honneur sur Edumalin !",
                video: "https://www.youtube.com/embed/TdYjJqF9oOg",
                picture: "/testimonies/thumbnail03.jpg"
            },
            {
                text: "\"Utilisation en classe inversée : présenter les notions avant le début de la séquence\" et \"en remédiation\" La rencontre entre les Maths/Sciences et Edumalin en LP.",
                video: "https://www.youtube.com/embed/F0NvI5dd5Eo",
                picture: "/testimonies/thumbnail04.jpg"
            },
            {
                text: "Échange avec Xavier Domenech, Proviseur d'un LPO à Sarcelles et fin connaisseur d'Edumalin.",
                video: "https://www.youtube.com/embed/-_pofKauMQo",
                picture: "/testimonies/thumbnail05.jpg"
            },
            {
                text: "E.Rouve est directeur de l'IFTO, école de production en chaudronnerie en apprentissage et nous parle d'Edumalin dans le cadre de son projet pédagogique du \"Faire pour apprendre\".",
                video: "https://www.youtube.com/embed/UxHUi8se_0w",
                picture: "/testimonies/thumbnail06.jpg"
            },

            {
                name: "B. Tratnjek",
                title: "Agrégée d'Histoire-Géographie, coordinatrice du réseau REP Annemasse 1",
                text: "Je suis ravie de trouver un outil qui soit pensé à la fois pour les élèves et les enseignants, sans tomber dans les neuromythes mercantiles, avec une véritable réflexion pédagogique. Vous avez réellement fait un travail formidable !"
            },
            {
                name: "N. Deroo",
                title: "Principal collège G.Tillion - Paris",
                text: "Edumalin a permis de répondre à une 1ère hypothèse : si les élèves ne travaillent pas à la maison, c’est qu’ils ne savent pas le faire à priori. L'outil leur permet de le faire très concrètement et au quotidien."
            },
            {
                name: "A. Lahon",
                title: "Professeure d'Histoire-Géographie, collège G.Rouault REP+ - Paris",
                text: "Le constat est simple, votre outil devrait être partout."
            },
            {
                name: "L. Bazin",
                title: "Professeur de Mathématiques, collège G.Tillion - Paris",
                text: "Plateforme efficace et pertinente car le système permet de créer une interaction très dynamique répondant au défi de l’enseignement à distance. Cette grande flexibilité permet de remédier en explicitant les notions et différents supports."
            },
            {
                name: "N. Thuiller",
                title: "Professeure de Mathématiques, collège P.Verlaine, Sartrouville (78)",
                text: "C’est un outil que je trouve très pratique et j’aimerais continuer à l’utiliser."
            },
            {
                name: "M. Laxague",
                title: "PLP Maths/Sciences, LP Aizpurdi, Hendaye (64)",
                text: "Avec mes TCAP , j'ai affecté en amont de la leçon ( Théorème de Pythagore et sa réciproque) le QCM que j'ai trouvé dans votre bibliothèque. Cela a permis aux élèves de se remettre en mémoire ce théorème (déjà vu au collège) et la leçon a donc été plus rapide. Les élèves ont apprécié cette activité et en redemandent. L'expérience est donc positive . Ils demandent même à être évalués ainsi."
            }
        ],
        technicalFaqs: [
            {
                title: "Où puis-je trouver Edumalin dans mon ENT ?",
                text: "C’est très simple : Edumalin étant déployé via le GAR vous trouverez la plateforme en cliquant sur vos applications pédagogiques <b>de l’ENT</b> puis en trouvant le <b>GAR-Médiacentre</b>.\n" +
                    "Une barre de recherche vous est proposée et en tapant « Edumalin » dedans vous accèderez à l’outil."
            },
            {
                title: "Je suis parent et je ne trouve pas de compte Edumalin. Est-ce normal ?",
                text: "Oui c’est tout à fait normal. A ce jour, il n’y a <b>pas de compte pour un profil « parents »</b> dans le GAR. Vous pouvez néanmoins (et nous vous le recommandons chaleureusement) suivre les travaux de vos enfants via leur compte ENT.\n" +
                    "Les contenus guidant Edumalin vous permettent d’aider vos enfants même quand vous n’êtes pas spécialiste d’une matière.\n"
            },
            {
                title: "Avec quels navigateurs Edumalin est-il compatible ?",
                text: "Nous recommandons de passer par <b>Chrome, Mozilla ou Safari</b> dans des versions relativement récentes. Il n’y a pas, à notre connaissance, de difficultés particulières de navigation et/ou accès aux fonctionnalités en passant par ces navigateurs."
            },
            {
                title: "Avec quel type d’appareil utiliser Edumalin ?",
                text: "L’outil est développé en Responsive Design. Cela signifie qu’il s’adapte à toutes les tailles d’écran. Vous pouvez donc travailler <b>sur smartphone, tablette et ordinateur fixe ou portable</b>.\n" +
                    "Nous vous conseillons, pour plus de confort, de privilégier le mode paysage sur smartphone et tablette.\n"
            },
            {
                title: "Doit-on télécharger les listes d’élèves dans Edumalin ? Comment créer nos classes ?",
                text: "Non absolument pas en raison du déploiement via le GAR.  C’est pour cette raison que la <b>1ère action à mener avec vos élèves est de leur demander de cliquer une 1ère fois sur Edumalin via leur compte ENT</b>.\n" +
                    "C’est ce 1er clic qui va créer les classes et activer du côté professeurs les comptes des élèves. Cette étape est fondamentale pour pouvoir, ensuite affecter du travail à vos élèves et classes.\n"
            },
            {
                title: "En tant que professeur ai-je accès seulement à mes classes ou à toutes les classes de mon établissement ?",
                text: "Edumalin est un outil pensé pour le temps de travail personnel. Que ce soit en collège avec « Devoirs Faits » et « Devoirs Faits à distance » ou en LGT et LP avec l’Accompagnement Personnalisé, il n’est pas rare d’animer des séances dans des matières et/ou avec des élèves que vous n’avez pas habituellement en classe.\n" +
                    "<b>C’est pour cette raison qu’avec un compte professeur, vous accédez à toutes les divisions de votre établissement</b>. Cet accès facilite aussi grandement le travail du professeur principal qui peut en quelques clics accéder à toutes les informations de suivi du travail personnel et/ou autonome de sa classe. Très précieux pour préparer des conseils pédagogiques ou des points précis avec l’équipe pédagogique et/ou l’équipe de direction.\n"
            },
            {
                title: "Je n’ai ni GAR, ni ENT, puis-je accéder à Edumalin ?",
                text: "<b>Oui à 100%</b>. Si vous êtes dans ce cas de figure, nous pourrons envisager ensemble de déployer une plateforme en propre.\n" +
                    "Pour ce faire, nous créerons pour vous les identifiants et mots de passe d’accès pour les formateurs et les élèves de votre structure.\n"
            }
        ],
        pedagogicalFaqs: [
            {
                title: "Quelles sont les matières disponibles ? Où les trouver ?",
                text: "Chaque professeur, dans sa matière peut créer des contenus dans l’éditeur de ressources et ainsi s’appuyer sur toutes les fonctionnalités offertes par la plateforme.\n" +
                    "Suivant la version de cette dernière, vous trouverez les bibliothèques de ressources suivantes :\n" +
                    "<ul><li><b>Primaire</b> : Maths et Français pour les niveaux CM1-CM2</li>" +
                    "<li><b>Collège</b> : Mathématiques, Français et HG pour tous les niveaux (6e à 3e). Maths et Français pour tous les niveaux de SEGPA (6e à 3e).</li>" +
                    "<li><b>Lycée Professionnel</b> : Maths/Sciences, Français /Histoire-géo et PSE pour les CAP ainsi que pour les 3 niveaux de BacPro (2nde, 1ère, Terminales).</li>" +
                    "<li><b>Lycée Général et Technologique</b> : Maths et Français pour les 2nde.</li></ul>\n" +
                    "<u>A noter</u> : pour chaque version nous produisons des contenus au fur et à mesure de type « Les Fondamentaux » qui sont des pré-requis dans les matières concernées. Nous déployons également des parcours de type « Préparation aux examens » qui traitent, « façon Edumalin » des sujets d’examens (Brevet, BacPro…).\n"
            },
            {
                title: "Est-ce que je peux personnaliser les modules Edumalin ?",
                text: "<b>Oui tout à fait</b>. \n" +
                    "En tant que professeur et suivant la version de la plateforme à laquelle vous avez accès, vous pouvez utiliser les parcours Edumalin à votre guise. Un simple clic sur « Personnaliser » du parcours de votre choix et il basculera dans votre espace « Mon travail ». Dès lors, il vous appartient et vous en faites ce que vous voulez.\n" +
                    "N’hésitez pas à consulter <b>le guide d’utilisation</b> dans la rubrique TutoMalin pour bien comprendre cette manipulation."
            },
            {
                title: "Est-ce que je peux créer des parcours ? Combien de temps cela peut-il prendre ?",
                text: "Oui bien sûr. D’après les retours en notre possession, <b>construire un module nécessite 20-25’</b> en maîtrisant les quelques clics nécessaires dans l’outil d’édition.\n" +
                    "Dès votre arrivée sur le tableau de bord vous pouvez accéder à <b>un outil de création de parcours</b>. Là encore, la consultation du guide d’utilisation dans la rubrique TutoMalin peut s’avérer intéressante avant de se lancer dans les 3 grandes étapes de création :\n" +
                    "<ol><li>Choisir l’activité que vous souhaitez expliciter : synthèse à mémoriser, document à analyser….</li>" +
                    "<li>Expliciter les 5-6 étapes du raisonnement ou de la démarche nécessaires pour effectuer l’activité sur une feuille simple.</li>" +
                    "<li>Intégrer ces étapes et le support dans l’outil d’édition pour créer le module.</li></ol>"
            },
            {
                title: "A quels parcours les élèves ont-ils accès par défaut ?",
                text: "Chaque élève a accès, sur son <b>tableau de bord, a un mode « s’entraîner » en autonomie</b>.\n" +
                    "Concrètement il pourra réaliser tous les parcours Edumalin de son niveau ainsi que les parcours créés par ses professeurs. Refaire un travail devient ainsi très simple.\n" +
                    "Exemple : un élève de 6ème accède aux parcours de son niveau en Maths, Français et HG + les éventuels parcours construits par ses professeurs.\n" +
                    "<u>A noter</u> : dès qu’un élève a réalisé un parcours, les professeurs de l’établissement ont accès à toutes les données de suivi : taux de réussite, visualisation de chaque étape du raisonnement, etc..\n"
            },
            {
                title: "Un élève peut-il refaire un module ou un parcours ?",
                text: "Oui. Lorsque <b>vous affectez un parcours à vos élèves, celui-ci bascule automatiquement dans le mode « s’entraîner »</b> décrit dans cette FAQ. Après une 1ère tentative et vos éventuels retours, l’élève peut reprendre ce travail en autonomie pour consolider ses connaissances et compétences."
            },
            {
                title: "Que trouve-t-on dans les Packs « Les Fondamentaux » ?",
                text: "Les Packs « Les Fondamentaux » comptent en général une quinzaine de parcours en Maths et Français, considérés comme des <b>essentiels à maîtriser</b> pour être à l’aise.\n" +
                    "Ces packs ne sont pas exhaustifs mais permettent, en particulier, de <b>proposer un travail adapté et personnalisé</b> à des élèves ou jeunes qui n’auraient pas complètement consolidé les compétences et savoirs nécessaires dans les niveaux antérieurs.\n"
            },
            {
                title: "De quels outils de suivi disposons-nous ?",
                text: "La gamme d’outils de suivi d’Edumalin est très riche et particulièrement innovante. Vous disposez ainsi :\n" +
                    "<ul><li>D’une <b>visualisation au niveau de vos classes</b> : très pratique pour le professeur principal</li>" +
                    "<li>D’outils de <b>visualisation individuels</b> : avec les résultats aux modules par élève mais aussi, et surtout, une visualisation et compréhension très fine du raisonnement, de la démarche des élèves pour effectuer leur travail. " +
                    "A chaque étape, vous pouvez laisser un commentaire écrit ou oral pour remédier à la difficulté rencontrée.</li>" +
                    "<li><b>D’une visualisation des difficultés rencontrées par votre classe dans un travail</b>. Avantage : voir en un instant le ou les endroits du raisonnement qui ont en majorité posé problème à vos élèves. Idéal pour reprendre lors du cours suivant et encore mieux articuler temps en classe et temps de travail personnel.</li></ul>"
            }
        ],
        team: [
            [
                {
                    name: "François",
                    role: "Fondateur et Président d'Edumalin",
                    email: "francois@edumalin.fr",
                    picture: "/img/team/francois.jpg"
                },
                {
                    name: "Chloé",
                    role: "Adjointe du Président",
                    email: "chloe@edumalin.fr",
                    picture: "/img/team/chloe.jpg"
                },
                {
                    name: "Aiko",
                    role: "Assistant pédagogique numérique",
                    picture: "/img/team/aiko.png"
                }
            ],
            [
                {
                    name: "Laure",
                    role: "Ingénieure Pédagogique, Responsable éditoriale et de projet collections",
                    email: "laure@edumalin.fr",
                    picture: "/img/team/laure.jpg"
                },
                {
                    name: "Christelle",
                    role: "Ingénieure pédagogique chargée d'inclusion",
                    email: "christelle@edumalin.fr",
                    picture: "/img/team/christelle.jpg"
                },
                {
                    name: "Pauline",
                    role: "Ingénieure pédagogique, Responsable du projet TNE et du projet Tactimalin",
                    email: "pauline@edumalin.fr",
                    picture: "/img/team/pauline.jpg"
                },
                {
                    name: "Céline",
                    role: "Ingénieure pédagogique, Responsable de projet R&D pédagogique",
                    email: "celine@edumalin.fr",
                    picture: "/img/team/celine.png"
                },
                {
                    name: "Caroline",
                    role: "Assistante opérationnelle pédagogie, développement commercial et administratif",
                    email: "caroline@edumalin.fr",
                    picture: "/img/team/caroline.png"
                },
            ],
            [
                {
                    name: "Emmanuelle",
                    role: "Responsable qualité expérience utilisateur",
                    email: "emmanuelle@edumalin.fr",
                    picture: "/img/team/emmanuelle.jpg"
                },
                {
                    name: "Adrien",
                    role: "Responsable communication, marketing et développement commercial",
                    email: "adrien@edumalin.fr",
                    picture: "/img/team/adrien.jpg"
                },
                {
                    name: "Tracy",
                    role: "Chargée de développement commercial, marketing et communication",
                    email: "tracy@edumalin.fr",
                    picture: "/img/team/tracy.jpg"
                },
                {
                    name: "Alice",
                    role: "Conseillère projets scientifiques",
                    picture: "/img/team/alice.jpg",
                    logo: {
                        imgUrl: "img/team/cogx-logo.png",
                        siteUrl: "https://cogx.fr/"
                    }
                }
            ],
            [
                {
                    name: "Sébastien",
                    role: "Chef de projet technique et R&D",
                    picture: "/img/team/seb.jpg"
                },
                {
                    name: "Stéphane",
                    role: "Responsable Technique et R&D",
                    email: "stephane@edumalin.fr",
                    picture: "/img/team/stephane.jpg"
                }
            ]
        ],
        webinaires
    }
};

export default async function getConfig(): Promise<CMSConfiguration> {
    return config;
}