
    import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
    import {CMSBaseComp, CMSComponent} from "@snark/cms";
    import EduTitle from "@/components/commons/EduTitle.vue";
    import EduTeamMember from "@/components/cards/EduTeamMember.vue";
    import {TeamMember} from "@/types";

    @Component({
        name: "EduTeam",
        components: {EduTeamMember, EduTitle},
    })
    export default class EduTeam extends Vue implements CMSBaseComp {
        // --- props
        @Prop(Object) context!: CMSComponent;

        // --- data
        
        // --- computed
        get rows(): Array<Array<TeamMember>> {
            return this.cms.getData("team");
        }

        // --- methods
        
        // --- watch
        
        // --- hooks
    }
